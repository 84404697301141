import React, {useEffect, useState, useCallback, useContext} from 'react'
import { FormControlLabel, makeStyles, Switch } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import { useMutation } from "react-apollo"
import DeleteIcon from '@material-ui/icons/Delete'
import styles from '../../../../material-ui/assets/jss/material-kit-react/views/componentsSections/basicsStyle.js'
import CheckIcon from '@material-ui/icons/Check'
import { TagView } from '../../../../components/TagView/TagView'
import {AlertBox, Loader} from '../../../../components'
import { EditPostForm } from './EditPostForm'
import {DELETE_POST, GET_ALL_POSTS_ALL_FIELD} from '../../../../apis/posts'
import {AuthContext} from '../../../../context/auth'
const useStyle = makeStyles(styles)

export const SinglePost = props => {
    const context = useContext(AuthContext);

    const [editPost, setEditPost] = useState(false)
    const classes = useStyle()
    const {
        index,
        post: {
            id,
            showBadge,
            postHeading,
            showInSlider,
            showInNewsBoard,
            showPost,
            category,
            tags,
            likeCount,
            commentCount,
            body,
            date: {
                postCreatedAt,
                applicationStartDate,
                lastDateForApplication,
                feePayLastDate,
                admitCardDate,
                examDate,
                resultDate,
            },
            vacancy,
        },
    } = props

    const [deletePost, {error: deleteError, loading: deleteLoading}] =  useMutation(DELETE_POST, {
        variables : {
            postId: id
        },
        update(proxy,result){
            props.refetch()
        },
        onError : (err) => {
            if(err.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
                context.logout()
            }
        }

    })

    const handleDelete = id => {
        AlertBox(
            'Delete Category',
            'Are you sure, you want to delete this post?',
            deletePostAction
        )
    }
    function deletePostAction (){
        deletePost()
    }
    const handleEdit = () => {
        setEditPost(true)
    }

    const closeEditModal = () => {
        setEditPost(false)
    }
    return (
        <div>
            {deleteLoading && <Loader/>}
            <div className={'row row-body'}>
                <div>{index}</div>
                <div>
                    {category.length > 0 &&
                        category.map((tag, index) => (
                            <span key={index}>{tag.name}</span>
                        ))}
                </div>
                <div className={'post-body'}>
                    <div className={'post-heading'}>{postHeading}</div>
                    <div className={'tag-container'}>
                        <TagView
                            type={'circle'}
                            text={'Application Start'}
                            value={applicationStartDate || 'Coming soon'}
                        />
                        <TagView
                            type={'circle'}
                            text={'Application ends'}
                            value={lastDateForApplication || 'Coming soon'}
                        />
                        <TagView
                            type={'circle'}
                            text={'Fee pay'}
                            value={feePayLastDate || 'Coming soon'}
                        />
                        <TagView
                            type={'circle'}
                            text={'Admit Card'}
                            value={admitCardDate || 'Coming soon'}
                        />
                        <TagView
                            type={'circle'}
                            text={'Exam Date'}
                            value={examDate || 'Coming soon'}
                        />
                        <TagView
                            type={'circle'}
                            text={'Result Date'}
                            value={resultDate || 'Coming soon'}
                        />
                    </div>
                    <div className={'tag-container'}>
                        <div className={'tags'}>
                            {tags.length > 0 &&
                                tags.map((tag, index) => (
                                    <TagView
                                        type={'circle'}
                                        key={index}
                                        text={'Tags'}
                                        value={tag.name}
                                    />
                                ))}
                        </div>
                        <TagView
                            type={'box'}
                            text={'Vacancy'}
                            value={vacancy}
                        />
                        <TagView
                            type={'box'}
                            text={'Likes'}
                            value={likeCount}
                        />
                        <TagView
                            type={'box'}
                            text={'Comments'}
                            value={commentCount}
                        />
                    </div>
                </div>
                <div>{postCreatedAt}</div>
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                size={'small'}
                                checked={showInSlider}
                                value="slider"
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                }}
                                disabled={true}
                            />
                        }
                        classes={{
                            label: classes.label,
                        }}
                        label={''}
                        className={'switch'}
                    />
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                size={'small'}
                                checked={showInNewsBoard}
                                value="newsBoard"
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                }}
                                disabled={true}
                            />
                        }
                        classes={{
                            label: classes.label,
                        }}
                        label={''}
                        className={'switch'}
                    />
                </div>
                <div>
                    <CreateIcon
                        className={'action-btn edit'}
                        fontSize={'small'}
                        onClick={() => handleEdit(id)}
                    />
                    <DeleteIcon
                        className={'action-btn del'}
                        fontSize={'small'}
                        onClick={() => handleDelete(id)}
                    />
                </div>
            </div>

            {editPost && <EditPostForm
                open={editPost}
                closeEditModal={closeEditModal}
                data={props.post}

            />}
        </div>
    )
}
