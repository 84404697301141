import gql from 'graphql-tag'

const LOGIN_USER = gql`
    mutation login($username: String!, $password: String!) {
        login(username: $username, password: $password) {
            id
            email
            username
            token
            createdAt,
            usertype
        }
    }
`;

const REGISTE_USER = gql`
    mutation register($username: String!, $email: String!, $password: String!) {
        register(
            registerInput: {
                username: $username
                email: $email
                password: $password
                confirmPassword: $password
            }
        ) {
            id
            email
            username
            token
            createdAt
        }
    }
`;

export  {
    LOGIN_USER,
    REGISTE_USER,
}
