import React from 'react';
import gql from 'graphql-tag';

export const GET_ALL_POSTS_ALL_FIELD = gql`
     query getPosts($postSearchInput: PostSearchInput, $page:Int, $size: Int, $column:String ){
        getPosts(postSearchInput: $postSearchInput, page: $page size: $size, column: $column){
            id
            showBadge
            showInSlider
            sliderHeading
            showInNewsBoard
            newsBoardHeading
            showPost
            postHeading
            postDescription
            vacancy
            education{
                key
                name
            }
            region{
                key
                name
            }
            category{
                key
                name
                visible
                order
            }
            tags {
                key
                name
            }
            views
            comments{
                id
                body
                createdAt
                username
                anonymous{
                    name
                    email
                    isApproved
                }
            }
            attachment {
                jobSourceName
                linkLabel
                linkUrl
                urlType
                fileDetail {
                    filePath
                    fileName
                    createdAt
                    folder
                }
            }
            body
            date {
                postCreatedAt
                applicationStartDate
                lastDateForApplication
                feePayLastDate
                admitCardDate
                examDate
                resultDate
            }
            likeCount,
            commentCount
            
        }
    }
`;

export const GET_SINGLE_POST_ALL_FIELD = gql`
    query getPost($postId: ID!)
    {
        getPost(postId: $postId){
            id
            showPost
            postHeading
            postDescription
            vacancy
            education{
                name
            }
            region{
                key
                name
            }
            category{
                key
                name
                visible
                order
            }
            tags {
                key
                name
            }
            views
            comments{
                id
                body
                createdAt
                username
                anonymous{
                    name
                    email
                    isApproved
                }
            }
            attachment {
                jobSourceName
                linkLabel
                linkUrl
                urlType
                fileDetail {
                    filePath
                    fileName
                    createdAt
                    folder
                }
            }
            body
            date {
                postCreatedAt
                applicationStartDate
                lastDateForApplication
                feePayLastDate
                admitCardDate
                examDate
                resultDate
            }
            likeCount,
            commentCount
        }
    }
`;

export const GET_ALL_POSTS_HOME = gql`
    query getPosts(
        $order: Int,
    )
    {
        getPosts(
            postSearchInput : {
                category: {
                    order: $order
                }
            }
            page: 1,
            size: 20
        ) {

            id
            showBadge
            postHeading,
            category {
                key
                name
            }
            tags{
                key
                name
            }
            date{
                postCreatedAt
            }
            vacancy
            likeCount,
            commentCount
            views

        }
    }
`;

export const GET_ALL_POSTS_SLIDER = gql`
    query getPosts(
        $showInSlider: Boolean,
    )
    {
        getPosts(
            postSearchInput : {
                showInSlider: $showInSlider
            },
            page: 1,
            size: 20
        ) {
        
            id
            showInSlider
            sliderHeading
            postHeading
            postDescription
            category {
                key
                name
            }
            views
            date{
                postCreatedAt
            }
            likeCount,
            commentCount
            
        }
    }
`;
export const GET_ALL_POSTS_NEWS_BOARD = gql`
    query getPosts(
        $showInNewsBoard: Boolean
    )
    {
        getPosts(
            postSearchInput : {
            showInNewsBoard: $showInNewsBoard
            }
        ) {
            id
            showInNewsBoard
            newsBoardHeading
            postHeading
            views
            category {
                key
                name
            }
            date{
                postCreatedAt
            }
        }
    }
`;

export const UPDATE_VIEW = gql`
    mutation updateView($postId: ID!){
        updateView(postId: $postId){
            id
            views
        }
    }
`;

export const CREATE_POST = gql`
    mutation createPost(
        $showBadge: Boolean!
        $showInSlider: Boolean!
        $sliderHeading: String
        $showInNewsBoard: Boolean!
        $newsBoardHeading: String
        $showPost: Boolean!
        $postHeading: String!
        $postDescription: String!
        $vacancy: String!
        $education: [PostObjectInput!]!
        $region: [PostObjectInput!]!
        $category: [PostObjectInput!]!
        $tags: [PostObjectInput!]!
        $postCreatedAt: String
        $applicationStartDate: String
        $lastDateForApplication: String
        $feePayLastDate: String
        $admitCardDate: String
        $examDate: String
        $resultDate: String
        $attachmentData: [AttachmentInput]!
        $body: String!
    ) {
        createPost(
            postInput: {
                showBadge: $showBadge
                showInSlider: $showInSlider
                sliderHeading: $sliderHeading
                showInNewsBoard: $showInNewsBoard
                newsBoardHeading: $newsBoardHeading
                showPost: $showPost
                postHeading: $postHeading
                postDescription: $postDescription
                vacancy: $vacancy
                education: $education
                region: $region
                category: $category
                tags: $tags
                attachment: $attachmentData
                body: $body
                date: {
                    postCreatedAt: $postCreatedAt
                    applicationStartDate: $applicationStartDate
                    lastDateForApplication: $lastDateForApplication
                    feePayLastDate: $feePayLastDate
                    admitCardDate: $admitCardDate
                    examDate: $examDate
                    resultDate: $resultDate
                }
            }
        ) {
            id
            showBadge
            showInSlider
            sliderHeading
            showInNewsBoard
            newsBoardHeading
            showPost
            postHeading
            postDescription
            vacancy
            education{
                name
            }
            region{
                key
                name
            }
            category{
                key
                name
            }
            tags {
                key
                name
            }
            attachment {
                jobSourceName
                linkLabel
                linkUrl
                urlType
                fileDetail {
                    filePath
                    fileName
                    createdAt
                    folder
                }
            }
            body
            vacancy
            views
            date {
                postCreatedAt
                applicationStartDate
                lastDateForApplication
                feePayLastDate
                admitCardDate
                examDate
                resultDate
            }
            likeCount
            commentCount
        }
    }
`;
export const EDIT_POST = gql`
    mutation editPost( 
        $id: ID!
        $showBadge: Boolean,
        $showInSlider: Boolean,
        $sliderHeading: String,
        $showInNewsBoard: Boolean,
        $newsBoardHeading: String,
        $showPost: Boolean,
        $postHeading: String,
        $postDescription: String,
        $body: String,
        $category: [PostObjectInput],
        $tags: [PostObjectInput],
        $postCreatedAt: String
        $applicationStartDate: String
        $lastDateForApplication: String
        $feePayLastDate: String
        $admitCardDate: String
        $examDate: String
        $resultDate: String
        
    ){
        editPost(postEditInput: {
            id: $id,
            showBadge: $showBadge,
            showInSlider: $showInSlider,
            sliderHeading: $sliderHeading,
            showInNewsBoard: $showInNewsBoard,
            newsBoardHeading: $newsBoardHeading,
            showPost : $showPost,
            postHeading: $postHeading,
            postDescription :$postDescription,
            body: $body,
            category: $category,
            tags: $tags,
            date: {
                postCreatedAt: $postCreatedAt
                applicationStartDate: $applicationStartDate
                lastDateForApplication: $lastDateForApplication
                feePayLastDate: $feePayLastDate
                admitCardDate: $admitCardDate
                examDate: $examDate
                resultDate: $resultDate
            }
            
        }){
            id,
            showBadge,
            showInSlider,
            sliderHeading,
            showInNewsBoard,
            newsBoardHeading,
            showPost,
            postHeading,
            postDescription,
            body,
            category{
                key
                name
            }
            tags {
                key
                name
            }
            date {
                postCreatedAt
                applicationStartDate
                lastDateForApplication
                feePayLastDate
                admitCardDate
                examDate
                resultDate
            }
    }
    }
`;

export const DELETE_POST = gql`
    mutation deletePost($postId: ID!){
        deletePost(postId: $postId)
    }
`;
