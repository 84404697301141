import React, {useContext} from 'react'
import {createBrowserHistory as history} from "history"
import { Redirect } from 'react-router-dom'
import {AuthContext} from './context/auth'


const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const { user } = useContext(AuthContext)
    if ((!user || (user && user.usertype !== 'admin')) && location !== `/login`) {
        return <Redirect to="/" />
    }
    return <Component {...rest} />
}
export default PrivateRoute
