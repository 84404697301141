import React, { useState, useContext, Fragment, useEffect } from 'react'
import { PopuoModal } from '../../../../components/PopupModal/PopuoModal'
import { Button, FormControlLabel, Switch } from '@material-ui/core'
import GridContainer from '../../../../material-ui/components/Grid/GridContainer'
import Divider from '@material-ui/core/Divider'
import GridItem from '../../../../material-ui/components/Grid/GridItem'
import { useForm } from '../../../../utils/customHooks'
import styles from '../../../../material-ui/assets/jss/material-kit-react/views/componentsSections/basicsStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import CustomInput from '../../../../material-ui/components/CustomInput/CustomInput'
import { Editor } from '@tinymce/tinymce-react'
import { Loader, ModifiedSelect } from '../../../../components'
import FormControl from '@material-ui/core/FormControl'
import Datetime from 'react-datetime'
import { useQuery, useMutation } from 'react-apollo'
import { CREATE_CATEGORY, GET_CATEGORY } from '../../../../apis/category'
import moment from 'moment'
import './index.scss'
import { EDIT_POST } from '../../../../apis/posts'
import { AuthContext } from '../../../../context/auth'
const useStyles = makeStyles(styles)

export const EditPostForm = props => {
    const classes = useStyles()
    const context = useContext(AuthContext)
    const { loading, error, data } = useQuery(GET_CATEGORY)
    const [content, setContent] = useState('')
    // console.log('data', data)
    const optionCategory =
        data &&
        data.getCategories
            .filter(
                item => item.categoryType && item.categoryType.typeCode === 1
            )
            .map((item, index) => ({
                key: index,
                name: item.categoryTitle,
                visible: item.visible,
                order: item.order,
            }))

    const optionTag =
        data &&
        data.getCategories
            .filter(
                item => item.categoryType && item.categoryType.typeCode === 4
            )
            .map((item, index) => ({
                key: index,
                name: item.categoryTitle,
                visible: item.visible,
                order: item.order,
            }))

    // console.log('props.data', props.data)
    const initialState = {
        id: props.data.id,
        showBadge: props.data.showBadge,
        showInSlider: props.data.showInSlider,
        sliderHeading: props.data.sliderHeading,
        showInNewsBoard: props.data.showInNewsBoard,
        newsBoardHeading: props.data.newsBoardHeading,
        showPost: props.data.showPost,
        postHeading: props.data.postHeading,
        postDescription: props.data.postDescription,
        category: props.data.category.map(item => {
            delete item.__typename
            return item
        }),
        tags: props.data.tags.map(item => {
            delete item.__typename
            return item
        }),
        postCreatedAt: props.data.date.postCreatedAt,
        applicationStartDate: props.data.date.applicationStartDate,
        lastDateForApplication: props.data.date.lastDateForApplication,
        feePayLastDate: props.data.date.feePayLastDate,
        admitCardDate: props.data.date.admitCardDate,
        examDate: props.data.date.examDate,
        resultDate: props.data.date.resultDate,
        body: props.data.body,
    }
    const { onChange, onSubmit, onSelect, resetForm, values } = useForm(
        editAction,
        initialState
    )


    useEffect(()=> {
        document.addEventListener("focusin", (e)=>{
            console.log("listening e.target", e.target)
            e.stopImmediatePropagation();
        })
    }, [])


    const handleDateChange = (field, value) => {
        onChange(field, moment(value).format('DD/MM/YYYY'))
    }

    const handleSelectChange = (value, name, optionValue, isMulti) => {
        onSelect(name, value)
    }

    const handleBodyChange = e => {
        e.stopImmediatePropagation();
        //setContent(e.target.getContent() );
        onChange('body', e.target.getContent())
    }

    const handleDescriptionChange = e => {
        //setContent(e.target.getContent() );
        onChange('postDescription', e.target.getContent())
    }

    const [editPosts, { error: editPostError, loading: editLoading }] = useMutation(
        EDIT_POST,
        {
            variables: {
                id: props.data.id,
                showBadge: values.showBadge,
                showInSlider: values.showInSlider,
                sliderHeading: values.sliderHeading,
                showInNewsBoard: values.showInNewsBoard,
                newsBoardHeading: values.newsBoardHeading,
                showPost: values.showPost,
                postHeading: values.postHeading,
                postDescription: values.postDescription,
                category: values.category,
                tags: values.tags,
                postCreatedAt: values.postCreatedAt,
                applicationStartDate: values.applicationStartDate,
                lastDateForApplication: values.lastDateForApplication,
                feePayLastDate: values.feePayLastDate,
                admitCardDate: values.admitCardDate,
                examDate: values.examDate,
                resultDate: values.resultDate,
                body: values.body,
            },
            update(proxy, result) {
                props.closeEditModal()
            },
            onError(err) {
                if(err.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
                    context.logout()
                }
            },
        }
    )
    function editAction() {
        editPosts()
    }
    return (
        <Fragment>
                <PopuoModal
                    open={props.open}
                    closeModal={props.closeEditModal}
                    title={'Edit Post'}
                    buttonContainer={
                        <Button autoFocus onClick={onSubmit} color="primary">
                            Save changes
                        </Button>
                    }
                    width={'lg'}
                >
                    {(editLoading || loading) && <Loader />}
                    <GridContainer className={'editModal'}>
                        <GridItem xs={12} sm={10} md={10} lg={10}>
                            <h4> Show this post in : </h4>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={values.showInSlider}
                                        onChange={event =>
                                            onChange(
                                                'showInSlider',
                                                event.target.checked
                                            )
                                        }
                                        value="showInSlider"
                                        name={'showInNewsBoard'}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                }}
                                label="Slider"
                                className={'switch1'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={values.showInNewsBoard}
                                        onChange={event =>
                                            onChange(
                                                'showInNewsBoard',
                                                event.target.checked
                                            )
                                        }
                                        value="showInNewsBoard"
                                        name={'showInNewsBoard'}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                }}
                                label="News board"
                                className={'switch2'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={values.showBadge}
                                        onChange={event =>
                                            onChange(
                                                'showBadge',
                                                event.target.checked
                                            )
                                        }
                                        value="showBadge"
                                        name={'showBadge'}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                }}
                                label="Show Badge"
                                className={'switch4'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={values.showPost}
                                        onChange={event =>
                                            onChange(
                                                'showPost',
                                                event.target.checked
                                            )
                                        }
                                        value="showPost"
                                        name={'showPost'}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                }}
                                label="Show Post"
                                className={'switch3'}
                            />
                        </GridItem>
                        {values.showInSlider && (
                            <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                className={'row'}
                            >
                                <CustomInput
                                    labelText="Slider Heading"
                                    id="sliderHeading"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        type: 'text',
                                        autoComplete: 'off',
                                        value: values.sliderHeading,
                                        disabled: !values.showInSlider,
                                        // error: errors && !!errors.Heading,
                                        onChange: e =>
                                            onChange(
                                                'sliderHeading',
                                                e.target.value
                                            ),
                                    }}
                                />
                            </GridItem>
                        )}
                        {values.showInNewsBoard && (
                            <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                className={'row'}
                            >
                                <CustomInput
                                    labelText="News board Heading"
                                    id="newsBoardHeading"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        type: 'text',
                                        autoComplete: 'off',
                                        value: values.newsBoardHeading,
                                        disabled: !values.showInNewsBoard,
                                        // error: errors && !!errors.Description,
                                        onChange: e =>
                                            onChange(
                                                'newsBoardHeading',
                                                e.target.value
                                            ),
                                    }}
                                />
                            </GridItem>
                        )}
                        <GridItem xs={12} sm={12} md={12} lg={12} className={'row'}>
                            <CustomInput
                                labelText="Post Heading"
                                id="postHeading"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    type: 'text',
                                    autoComplete: 'off',
                                    value: values.postHeading,
                                    // error: errors && !!errors.Heading,
                                    onChange: e =>
                                        onChange('postHeading', e.target.value),
                                }}
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={'row postDescription'}
                        >
                            <h4>Post Description</h4>
                            <Editor
                                mode="exact"
                                apiKey="wrd7uasp7bs8fss7mvs3dl4sikju6uupbuoemx3ex8h4mewp"
                                init={{
                                    height: 200,
                                    lineHeight: 1,
                                    menubar: false,
                                    toolbar: false,
                                    inline: false,
                                    branding: false,
                                    contextmenu:
                                        'link image imagetools table spellchecker p',
                                    quickbars_insert_toolbar: false,
                                    quickbars_selection_toolbar:
                                        'bold italic | quicklink h2 h3 h4 blockquote link emoticons template',
                                    paste_data_images: false,
                                    // paste_html_import: true,
                                    // paste_word_valid_elements: 'b,strong,i,em,h1,h2,table,td,tr,tbody,',
                                    // paste_webkit_styles: 'font-size',
                                    plugins: [
                                        'template',
                                        'emoticons',
                                        'link',
                                        'quickbars',
                                    ],
                                    // templates: [
                                    //     {
                                    //         title: 'New Table',
                                    //         description: 'creates a new table',
                                    //         content:
                                    //             '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                                    //     },
                                    //     {
                                    //         title: 'Starting my story',
                                    //         description: 'A cure for writers block',
                                    //         content: 'Once upon a time...',
                                    //     },
                                    //     {
                                    //         title: 'New list with dates',
                                    //         description: 'New List with dates',
                                    //         content:
                                    //             '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                                    //     },
                                    // ],
                                }}
                                value={values.postDescription}
                                onChange={handleDescriptionChange}
                            />
                        </GridItem>

                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            className={'row padding'}
                        >
                            <ModifiedSelect
                                isMulti={true}
                                options={optionCategory}
                                label="Select Category"
                                inputId="category"
                                placeholder="Select category"
                                optionValue="key"
                                optionLabel="name"
                                name="category"
                                selectevalue={values.category}
                                handleChangefn={handleSelectChange}
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            className={'row padding'}
                        >
                            <ModifiedSelect
                                isMulti={true}
                                options={optionTag}
                                label="Select Tag"
                                inputId="tag"
                                placeholder="Select tag"
                                optionValue="key"
                                optionLabel="name"
                                name="tags"
                                selectevalue={values.tags}
                                handleChangefn={handleSelectChange}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={6} className={'row'}>
                            <FormControl fullWidth>
                                <span>Job Posted at: </span>
                                <Datetime
                                    inputProps={{ placeholder: 'Created At' }}
                                    dateFormat="DD/MM/YYYY"
                                    timeFormat={false}
                                    className={'createdAt'}
                                    value={values.postCreatedAt}
                                    onChange={value =>
                                        handleDateChange('postCreatedAt', value)
                                    }
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            className={'row padding'}
                        >
                            <span>Application Start Date</span>
                            <Datetime
                                inputProps={{
                                    placeholder: 'Application Start Date',
                                }}
                                dateFormat="DD/MM/YYYY"
                                timeFormat={false}
                                className={'examLastDate'}
                                value={values.applicationStartDate}
                                onChange={value =>
                                    handleDateChange('applicationStartDate', value)
                                }
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            className={'row padding'}
                        >
                            <span>Last Date for Apply Online</span>
                            <Datetime
                                inputProps={{
                                    placeholder: 'Last Date for Apply Online ',
                                }}
                                dateFormat="DD/MM/YYYY"
                                timeFormat={false}
                                className={'lastDateApply'}
                                value={values.lastDateForApplication}
                                onChange={value =>
                                    handleDateChange(
                                        'lastDateForApplication',
                                        value
                                    )
                                }
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            className={'row padding'}
                        >
                            <span>Last Date Pay Exam Fee</span>
                            <Datetime
                                inputProps={{
                                    placeholder: 'Last Date Pay Exam Fee',
                                }}
                                dateFormat="DD/MM/YYYY"
                                timeFormat={false}
                                className={'examLastDate'}
                                value={values.feePayLastDate}
                                onChange={value =>
                                    handleDateChange('feePayLastDate', value)
                                }
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            className={'row padding'}
                        >
                            <span>Admit Card Available</span>
                            <Datetime
                                inputProps={{ placeholder: 'Admit Card Available' }}
                                dateFormat="DD/MM/YYYY"
                                timeFormat={false}
                                className={'admitCardAvailable'}
                                value={values.admitCardDate}
                                onChange={value =>
                                    handleDateChange('admitCardDate', value)
                                }
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            className={'row padding'}
                        >
                            <span>Exam date</span>
                            <Datetime
                                inputProps={{ placeholder: 'Exam date' }}
                                dateFormat="DD/MM/YYYY"
                                timeFormat={false}
                                className={'examDate'}
                                value={values.examDate}
                                onChange={value =>
                                    handleDateChange('examDate', value)
                                }
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            className={'row padding'}
                        >
                            <span>Result Date</span>
                            <Datetime
                                inputProps={{ placeholder: 'Result Date' }}
                                dateFormat="DD/MM/YYYY"
                                timeFormat={false}
                                className={'resultDay'}
                                value={values.resultDate}
                                onChange={value =>
                                    handleDateChange('resultDate', value)
                                }
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12} lg={12} className={'row'}>
                            {/*<Editor />*/}
                            <Editor
                                apiKey="wrd7uasp7bs8fss7mvs3dl4sikju6uupbuoemx3ex8h4mewp"
                                init={{
                                    height: 500,
                                    menubar: true,
                                    value: { content },
                                    paste_data_images: true,
                                    paste_html_import: false,
                                    paste_word_valid_elements: 'b,strong,i,em,h1,h2,table,td,tr,tbody,',
                                    paste_webkit_styles: 'font-size',
                                    plugins: [
                                        'template',
                                        'emoticons',
                                        'searchreplace',
                                        'visualblocks',
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks',
                                        'insertdatetime', 'media', 'table', 'paste',
                                        'code help wordcount',
                                    ],
                                    toolbar:
                                        'undo redo | bold italic underline | fontsizeselect fontselect formatselect | alignleft aligncenter alignright alignjustify table|  numlist bullist | forecolor backcolor removeformat code| pagebreak | charmap emoticons | fullscreen  preview save | insertfile image media template link anchor codesample | ltr rtl',
                                    fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                                    templates: [
                                        {
                                            title: 'Fill Form Instruction',
                                            description: 'Insert Instruction',
                                            content:
                                                `
                                        <div class="fill-instruction"><Icon type="form" /><h2 style={{color: "#d81b60 !important;"}}>How to Fill Form<h2>
                                            <p>Candidate Read the Notification Before Apply the Recruitment Application Form.</p>
                                            <p>Kindly Check and College the All Document – Eligibility, ID Proof, Address Details, Basic Details.</p>
                                            <p>Kindly Ready Scan Document Related to Recruitment Form – Photo, Sign, ID Proof, Etc.</p>
                                            <p>Before Submit the Application Form Must Check the Preview and All Column Carefully.</p>
                                            <p>If Candidate Required to Paying the Application Fee Must Submit. If You have Not the Required Application Fees Your Form is Not Completed.</p>
                                            <p>Take A Print Out of Final Submitted Form.</p>
                                        </div>
                                        `,
                                        },
                                    ],
                                }}
                                value={values.body}
                                onEditorChange={handleBodyChange}
                            />
                        </GridItem>
                    </GridContainer>
                </PopuoModal>
        </Fragment>
    )
}
