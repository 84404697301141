import React from 'react'
import { Comment, Tooltip, Avatar } from 'antd'
import moment from 'moment'
import PersonIcon from '@material-ui/icons/Person'

import './index.scss'

export const CommentBox = props => {
    // console.log("props", props)
    const author =
        props.comment.username === 'Anonymous'
            ? props.comment.anonymous.name
            : props.comment.username
    return (
        <Comment
            author={<a>{author}</a>}
            avatar={<Avatar icon="user" alt="Han Solo" />}
            content={<p>{props.comment.body}</p>}
            datetime={
                <Tooltip title={moment().format('DD-MMM-YYYY HH:mm:ss')}>
                    <span>
                        {moment(new Date(props.comment.createdAt)).fromNow()}
                    </span>
                </Tooltip>
            }
        />
    )
}
