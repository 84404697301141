import * as React from 'react'
import 'antd/dist/antd.css';
import { Result, Button } from 'antd';
import {Layout} from "../../components"

export const PageNotFound = (props) => {
    return (
        <Layout>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary">Back Home</Button>}
            />
        </Layout>
    )
}
