import gql from 'graphql-tag'


export const LIKE_POST = gql`
    mutation likePost($postId: ID!)
    {
        likePost(postId: $postId){
            id
            likeCount
        }
    }
`;

export const COMMENT_ON_POST = gql`
    mutation createComment($postId: ID!, $body: String, $anonymousInput: AnonymousInput)
    {
        createComment(postId: $postId, body: $body, anonymousInput: $anonymousInput){
            id
            comments{
                id
                username
                body
                createdAt
                anonymous{
                    name
                    email
                    isApproved
                }
            }
        }
    }
`;
