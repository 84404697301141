import React, { useState, Fragment } from 'react'
import clsx from 'clsx'
import Select from 'react-select'
import PropTypes from 'prop-types'
import makeAnimated from 'react-select/animated'
import { useTheme } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'

import {
    Box,
    Typography,
    Paper,
    Chip,
    MenuItem,
    TextField,
} from '@material-ui/core'
import useStyles from './style'

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    )
}

NoOptionsMessage.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    )
}

Control.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    selectProps: PropTypes.object.isRequired,
}

function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    )
}

Option.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    )
}

Placeholder.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
}

function SingleValue(props) {
    const subLabelData = []
    if (props.selectProps.optionSubLabel) {
        subLabelData.push(
            <Typography
                key="main"
                className={props.selectProps.classes.singleValue}
            >
                {props.data[props.selectProps.optionLabel]}
            </Typography>
        )
        subLabelData.push(
            <Box
                pl={0.25}
                key="sub"
                className={props.selectProps.classes.subLabel}
            >
                ({props.data[props.selectProps.optionSubLabel]})
            </Box>
        )
    }
    return (
        <Fragment>
            {!props.selectProps.optionSubLabel ? (
                <Typography
                    className={props.selectProps.classes.singleValue}
                    {...props.innerProps}
                >
                    {props.children}
                </Typography>
            ) : null}
            {props.selectProps.optionSubLabel ? subLabelData : null}
        </Fragment>
    )
}

SingleValue.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
}

function ValueContainer(props) {
    return (
        <Box className={props.selectProps.classes.valueContainer}>
            {props.children}
        </Box>
    )
}

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    )
}

MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool,
    removeProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
}

function Menu(props) {
    return (
        <Paper
            square
            className={props.selectProps.classes.paper}
            {...props.innerProps}
        >
            {props.children}
        </Paper>
    )
}

Menu.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
}

const animatedComponents = makeAnimated()

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    animatedComponents,
}

export default function ModifiedSelect({
    label = 'Select Label',
    inputId = 'select-elem-id',
    placeholder = 'Search or Select ..',
    isMulti = false,
    options = [],
    optionValue = 'id',
    optionLabel = 'name',
    optionSubLabel = '',
    baseLanguage = '',
    async = false,
    name,
    selectevalue,
    handleChangefn,
}) {
    const classes = useStyles()
    const theme = useTheme()
    const [value, setValue] = useState('')
    // setValue({...value, selectevalue})
    //console.log('base', baseLanguage)

    let selectedOptions = []
    if (isMulti) {
        if (optionValue === 'key') {
            if (selectevalue && selectevalue.length) {
                const selectedValueKey = selectevalue.map(({key})=> key)
                selectedOptions = options.filter(({ key }) => selectedValueKey.includes(key))
            }
        } else {
            if (selectevalue && selectevalue.length) {
                selectedOptions = options.filter(({ id }) =>
                    selectevalue.includes(id)
                )
            }
        }

        if (baseLanguage !== '') {
            const sortedArr = selectedOptions.reduce((acc, element) => {
                if (element.key === baseLanguage) {
                    return [element, ...acc]
                }
                return [...acc, element]
            }, [])
            selectedOptions = sortedArr
        }
        //selectedOptions.reverse()
    } else {
        if (optionValue == 'key') {
            if (selectevalue !== 0) {
                selectedOptions = options.filter(
                    item => item.key == selectevalue
                )
            }
        } else {
            if (selectevalue !== 0) {
                selectedOptions = options.filter(
                    item => item.id == selectevalue
                )
            }
        }
    }

    function handleChange(value, event) {
        //console.log(value)
        if (event.action === 'pop-value' || event.action === 'remove-value') {
            if (event.removedValue.key !== baseLanguage) {
                setValue(value)
                handleChangefn(value, name, optionValue, isMulti)
            }
        } else {
            setValue(value)
            handleChangefn(value, name, optionValue, isMulti)
        }
    }

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    }

    function getOptionLabelFn(option) {
        return optionSubLabel
            ? `${option[optionLabel]} (${option[optionSubLabel]})`
            : `${option[optionLabel]}`
    }
    const Elem = async ? Select : Select

    return (
        <Box className={classes.root}>
            <Elem
                classes={classes}
                styles={selectStyles}
                inputId={inputId}
                placeholder={placeholder}
                TextFieldProps={{
                    label,
                    InputLabelProps: {
                        htmlFor: inputId,
                        shrink: true,
                    },
                }}
                cacheOptions
                defaultOptions
                options={options}
                getOptionLabel={getOptionLabelFn}
                getOptionValue={option => option[optionValue]}
                optionLabel={optionLabel}
                optionSubLabel={optionSubLabel}
                baseLanguage={baseLanguage}
                components={components}
                value={value === '' ? selectedOptions : value}
                //value={selectedOptions}
                name={name}
                maxSearchResults="10"
                onChange={handleChange}
                isMulti={isMulti}
            />
        </Box>
    )
}
