import React, {Component} from 'react'

class DatePicker extends Component {
    render() {
        return (
            <div>
                Date picker example will come here.
            </div>
        )
    }
}

export default DatePicker
