import React from 'react'
import './index.scss'
export const TagView = props => {
    const { classes, type, text, value} = props
    return (
        <div className={"tag-view " + classes} tabIndex="0">
            {props.text && (
                <div className={'tag-label ' + type}>
                    {props.icon && <i className="heart icon" />} {text}
                </div>
            )}
            {value !== null && (
                <span className={'tag-value'}>{value}</span>
            )}
        </div>
    )
}
