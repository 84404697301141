import * as React from 'react'
import {Layout, Slider, Welcome} from "../../components"
export const About = (props) => {
    return (
        <Layout>
            <Welcome/>
            This is an about page.
        </Layout>
    )
}
