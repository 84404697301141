import React, { useState } from 'react'
import { ToastProvider, useToasts } from 'react-toast-notifications'
import thumpImage from '../../assets/thumbImage.gif'
import { useMutation, useQuery } from 'react-apollo'
import './index.scss'
import CustomInput from '../../material-ui/components/CustomInput/CustomInput'
import { useForm } from '../../utils/customHooks'
import { Icon } from 'antd'
import approx from 'approximate-number'
import GridContainer from '../../material-ui/components/Grid/GridContainer'
import GridItem from '../../material-ui/components/Grid/GridItem'
import { COMMENT_ON_POST, LIKE_POST } from '../../apis'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CommentIcon from '@material-ui/icons/Comment'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { formatNumber } from '../../utils/functions'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Loader } from '../index'
import PopularPosts from '../../pages/Posts/Post/PopularPosts'
const Sidebar = props => {
    // console.log('post', props.post)
    const { addToast } = useToasts()
    const [errors, setErrors] = useState({})
    const [action, setAction] = useState({
        like: false,
        comment: false,
    })
    const initialState = {
        name: '',
        email: '',
        comment: '',
    }

    const { onChange, resetForm, values } = useForm(postAction, initialState)

    const [likePost, { likeError, loading: likeLoading }] = useMutation(
        LIKE_POST,
        {
            variables: {
                postId: props.post.id,
            },
            update(proxy, result) {
                //resetForm()
            },
            onError(err) {
                console.log('Error', err)
                setErrors(
                    err.graphQLErrors[0] &&
                        err.graphQLErrors[0].extensions.errors
                )
            },
        }
    )

    const [commentPost, { commentError, commentLoading }] = useMutation(
        COMMENT_ON_POST,
        {
            variables: {
                postId: props.post.id,
                body: values.comment,

                anonymousInput: {
                    name: values.name,
                    email: values.email,
                },
            },
            update(proxy, result) {
                values.name = ''
                values.email = ''
                values.comment = ''
                setErrors({})
                addToast('Comment Posted successfully.', {
                    appearance: 'success',
                    autoDismiss: true,
                })
            },
            onError(err) {
                console.log('Error', err)
                setErrors(
                    err.graphQLErrors[0] &&
                        err.graphQLErrors[0].extensions.errors
                )
            },
        }
    )

    function postAction() {}

    const LikePost = () => {
        likePost()
        setAction({ ...action, like: true, comment: false })
    }
    const submitComment = () => {
        commentPost()
        setAction({ ...action, like: false, comment: true })
    }

    return (
        <div className="sidebar">
            {commentLoading && <Loader />}
            <div>
                <h1 className="header">Quick Actions</h1>
                {/*<div className="quick-action">*/}
                {/*    <a href="#attachment">*/}
                {/*        <div>*/}
                {/*            <Icon type="file-pdf" />*/}
                {/*            View application*/}
                {/*        </div>*/}
                {/*    </a>*/}
                {/*    <a href="#attachment">*/}
                {/*        <div>*/}
                {/*            <Icon type="printer" />*/}
                {/*            Print*/}
                {/*        </div>*/}
                {/*    </a>*/}
                {/*    <a href="#attachment">*/}
                {/*        <div>*/}
                {/*            <Icon type="download" /> Download*/}
                {/*        </div>*/}
                {/*    </a>*/}
                {/*    <a>*/}
                {/*        <div>*/}
                {/*            <Icon type="star" /> Bookmark Post*/}
                {/*        </div>*/}
                {/*    </a>*/}
                {/*</div>*/}
                <div className="post-social">
                    <div>
                        {approx(props.post && props.post.views)}{' '}
                        <VisibilityIcon color="primary" />{' '}
                    </div>
                    <div>
                        {approx(props.post && props.post.likeCount)}{' '}
                        <FavoriteIcon color="secondary" />{' '}
                    </div>
                    <div>
                        {approx(props.post && props.post.commentCount)}{' '}
                        <CommentIcon color="action" />{' '}
                    </div>
                </div>
                <div onClick={LikePost} className="like-btn">
                    Give this post a LIKE
                    <img src={thumpImage} alt="thumb" />
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className="comment-container">
                            <p>Quick Comment</p>
                            <form>
                                <div>
                                    <CustomInput
                                        labelText="Name"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            type: 'text',
                                            autoComplete: 'off',
                                            value: values.name,
                                            error: errors && !!errors.name,
                                            onChange: e =>
                                                onChange(
                                                    'name',
                                                    e.target.value
                                                ),
                                        }}
                                    />
                                    {!!errors.name && (
                                        <FormHelperText error={!!errors.name}>
                                            {' '}
                                            {errors.name}{' '}
                                        </FormHelperText>
                                    )}
                                </div>
                                <div>
                                    <CustomInput
                                        labelText="Email"
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            type: 'email',
                                            autoComplete: 'off',
                                            value: values.email,
                                            error: errors && !!errors.email,
                                            onChange: e =>
                                                onChange(
                                                    'email',
                                                    e.target.value
                                                ),
                                        }}
                                    />
                                    {!!errors.email && (
                                        <FormHelperText error={!!errors.email}>
                                            {' '}
                                            {errors.email}{' '}
                                        </FormHelperText>
                                    )}
                                </div>
                                <div>
                                    <CustomInput
                                        labelText="Comment"
                                        id="commnet"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            type: 'text',
                                            autoComplete: 'off',
                                            value: values.comment,
                                            error: errors && !!errors.body,
                                            onChange: e =>
                                                onChange(
                                                    'comment',
                                                    e.target.value
                                                ),
                                        }}
                                    />
                                    {!!errors.body && (
                                        <FormHelperText error={!!errors.body}>
                                            {' '}
                                            {errors.body}{' '}
                                        </FormHelperText>
                                    )}
                                </div>
                                <div>
                                    <div
                                        role={'submit'}
                                        disabled={
                                            !values.name ||
                                            !values.email ||
                                            !values.comment
                                        }
                                        className="submit"
                                        tabIndex={0}
                                        onClick={submitComment}
                                    >
                                        Submit
                                    </div>
                                </div>
                            </form>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
            <div>
                <ins className="adsbygoogle"
                     data-ad-client="ca-pub-1422138996530336"
                     data-ad-slot="9951776980"
                     data-ad-format="auto"
                     data-full-width-responsive="true"/></div>
            <div className="post-container">
                <div>Popular posts</div>
                <PopularPosts />
            </div>
            <div>{'Post panel'}</div>
            <div>
                <ins className="adsbygoogle"
                     data-ad-client="ca-pub-1422138996530336"
                     data-ad-slot="9951776980"
                     data-ad-format="auto"
                     data-full-width-responsive="true"/></div>
        </div>
    )
}

export default Sidebar
