import React, {useState, useEffect} from 'react'
import {Button} from '@material-ui/core'
import {PopuoModal} from '../../../../components/PopupModal/PopuoModal'

export const FilterPanel = (props) =>  {
        return (
            <PopuoModal
                open={props.open}
                closeModal={props.onCloseFilter}
                title={'Filter Post'}
                buttonContainer={
                    <Button autoFocus onClick={props.onCloseFilter} color="primary">
                        Save filter
                    </Button>
                }
            >
                Filter post will come here
            </PopuoModal>
        )

}
