import React, { useEffect, useState, useCallback, useContext, Fragment} from 'react'
import { useQuery } from "react-apollo"
import { GET_ALL_POSTS_ALL_FIELD } from "../../../../apis/posts"
import {Loader} from '../../../../components'
import {SinglePost} from './SinglePost'
import {FilterPanel} from './FilterPanel'
import CustomButton from '../../../../components/Button'
import "./index.scss"
import {Result} from 'antd'

export const PostList = (props) => {
    const [filter, setFilter] = useState(false)
    const {loading, error, data, refetch } = useQuery(GET_ALL_POSTS_ALL_FIELD, {
        // fetchPolicy: 'cache-and-network'
    })
    const handleFilterClick = () => {
        setFilter(true)
        // console.log("handle filter click")
    }

    const closeFilterPanel = () => {
        setFilter(false)
    }

    return (
        <div className={'post-list'}>
            {loading ? <Loader/> : (
                data && data.getPosts.length ? (
                    <Fragment>
                            <CustomButton
                                color={'info'}
                                children={'Filter'}
                                onClick={handleFilterClick}
                            />

                        <div className={'header'}>
                            <div>Sr. No</div>
                            <div>Category</div>
                            <div>Post details</div>
                            <div>Date</div>
                            <div>Slider</div>
                            <div>NewsBoard</div>
                            <div>Action</div>
                        </div>
                        {
                            data.getPosts.map((post, index) => {
                                return <SinglePost post={post} index={index+1} key={post.id} refetch={refetch}/>
                            })
                        }
                    </Fragment>
                ) : (
                    <Result
                        subTitle="No Post available/ Please refresh to load new posts."
                    />
                )
            )}

            { filter && <FilterPanel open={filter}  onCloseFilter={closeFilterPanel}/> }
        </div>
    )
}
