import React from 'react'
import {  Link } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from 'antd'
import GridContainer from '../../material-ui/components/Grid/GridContainer.js'
import GridItem from '../../material-ui/components/Grid/GridItem.js'
import CustomTabs from '../../material-ui/components/CustomTabs/CustomTabs.js'
import Face from '@material-ui/icons/Face'
import AssignmentIcon from '@material-ui/icons/Assignment';
import styles from '../../material-ui/assets/jss/material-kit-react/views/componentsSections/tabsStyle.js'
const useStyles = makeStyles(styles);

import './index.scss'
import {keyRoute} from '../../utils/functions'

const Tabs = props => {
    const classes = useStyles();
    const { data, loading } = props;
    return (
        <div className={classes.section}>
            <div className={classes.container}>
                <GridContainer className={'tabs'}>
                    <GridItem xs={12} sm={12} md={12}>
                        <h2>
                            <span className="fire">🔥🔥</span> Notice Board <span className="fire">🔥🔥</span>
                        </h2>
                        <CustomTabs
                            headerColor="rose"
                            tabs={[
                                {
                                    tabName: '',
                                    tabIcon: AssignmentIcon,
                                    tabContent: (
                                        <ol>
                                            {
                                                !loading && data && data.getPosts && data.getPosts.length ? data.getPosts.map((item, index) => {
                                                    let loc=""
                                                    if(typeof props.history !== 'undefined') {
                                                        loc = props.history.location && props.history.location.split("/")[0]
                                                    }
                                                    return (
                                                        <li key={index}>
                                                            {
                                                                item &&item.category && item.category[0].name.toLowerCase() !== "blog" ? (
                                                                    <Link to={ '/posts/' + keyRoute(item.category[0].key)+'/' + item.postHeading.split(" ").join("-")+"_"+item.id }>{item.newsBoardHeading}</Link>
                                                                    )
                                                                    : (
                                                                        <Link to={ '/blog/' + item.postHeading.split(" ").join("-")+"_"+item.id }>{item.newsBoardHeading}</Link>
                                                                    )
                                                            }

                                                        </li>
                                                    )}) : (
                                                    <Skeleton
                                                        active
                                                        loading={loading}
                                                        paragraph={{ rows: 5 }}
                                                    />
                                                )

                                            }

                                        </ol>
                                    ),
                                },
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    )
}

export default Tabs
