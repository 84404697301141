import React from 'react'
import { TagView } from '../TagView/TagView'
import './index.scss'

const PostList = props => {
    const { post } = props
    console.log('post', post)
    const {
        commentCount,
        likeCount,
        views,
        date: { postCreatedAt },
        vacancy,
    } = post
    return (
        <div className="post-list">
            <div className="post-heading">{post.postHeading}</div>
            <div className="post-body">
                <div
                    dangerouslySetInnerHTML={{ __html: post.postDescription }}
                />
            </div>
            <div className="button-control">
                <div className="left">
                    <TagView
                        classes={'post-tag'}
                        type={'circle'}
                        text={'Created At'}
                        value={postCreatedAt}
                    />
                </div>

                <div className="right">
                    <TagView
                        classes={'post-tag'}
                        type={'circle'}
                        text={'Vacancy'}
                        value={vacancy}
                    />
                    <TagView
                        classes={'post-tag'}
                        type={'circle'}
                        text={'Comments'}
                        value={commentCount}
                    />
                    <TagView
                        classes={'post-tag'}
                        type={'circle'}
                        text={'Likes'}
                        value={likeCount}
                    />
                    <TagView
                        classes={'post-tag'}
                        type={'circle'}
                        text={'Views'}
                        value={views}
                    />
                </div>
            </div>
        </div>
    )
}

export default PostList
