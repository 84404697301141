import React, {useState, useContext, useEffect} from 'react'
import {NavLink, useHistory} from 'react-router-dom'
import classesNames from "classnames"
import FacebookIcon from '@material-ui/icons/Facebook';
import SearchIcon from '@material-ui/icons/Search';
import Toggle from 'react-toggle'
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Header from '../../material-ui/components/Header/Header.js'
import CustomDropdown from '../../material-ui/components/CustomDropdown/CustomDropdown.js'
import CustomLink from '../../material-ui/components/CustomLink/customLink'
import {Logo} from '../../utils/svgs'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import {AuthContext} from '../../context/auth'
import './index.scss'

import styles from '../../material-ui/assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js'

const useStyles = makeStyles(styles)

export const Navbar = () => {
    const history = useHistory()
    const classes = useStyles()
    const [state, setState] = useState({
        loggedIn: false,
        user: ""
    })
    const {user, logout} = useContext(AuthContext)


    const [theme, setTheme] = useState({
        theme: false,
    })
    const onThemeChange = () => {
        setTheme({theme: !theme})
    }
    const handleChange = name => event => {
        setState({...state, [name]: event.target.checked})
    }



    const handleNavBarClick = (item, type) => {
        const action = item.split(" ").join("").toLowerCase();
        console.log(action, "action")
        switch(action){
            case 'signout' : {
                logout()
                return null
            }
            case 'home' : {
                history.push("/")
            }
            case 'latestjobs' : {
                history.push("/posts/latest-jobs")
            }
            default :
                console.log("check script for more into")
        }

    }

    const menuBar =
        user && user.usertype !== 'admin' ? (
            <div className={classes.section}>
                <div id="navbar" className={classes.navbar}>
                    <Header
                        brand="&nbsp;"
                        Logo={Logo}
                        fixed={true}
                        // color={"dark"}
                        rightLinks={
                            <List classes={classesNames(classes.list, classes.header)}>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Home
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/posts/results'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Result
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <CustomDropdown
                                        buttonText="Job Openings"
                                        dropdownHeader="Government Jobs"
                                        buttonProps={{
                                            className: classes.navLink,
                                            color: 'transparent',
                                        }}
                                        hoverColor={'rose'}
                                        dropdownList={[
                                            'Latest Jobs',
                                            {divider: true},
                                            'Railway',
                                            'Bank',
                                            'Defence',
                                            'Teaching',
                                            // { divider: true },
                                            // "Separated link",
                                            // { divider: true },
                                            // "One more separated link"
                                        ]}
                                        onClick={(param)=>handleNavBarClick(param, 'job')}
                                    />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/posts/admit-card'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Admit card
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/posts/answer-key'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Answer Key
                                    </NavLink>
                                </ListItem>
                                {/*<ListItem className={classes.listItem}>*/}
                                {/*    <CustomDropdown*/}
                                {/*        buttonText="Study Material"*/}
                                {/*        dropdownHeader="Government Jobs"*/}
                                {/*        buttonProps={{*/}
                                {/*            className: classes.navLink,*/}
                                {/*            color: 'transparent',*/}
                                {/*        }}*/}
                                {/*        hoverColor={'rose'}*/}
                                {/*        dropdownList={[*/}
                                {/*            'Railway',*/}
                                {/*            'Bank',*/}
                                {/*            'Defence',*/}
                                {/*            {divider: true},*/}
                                {/*            'Separated link',*/}
                                {/*            {divider: true},*/}
                                {/*            'One more separated link',*/}
                                {/*        ]}*/}
                                {/*        onClick={(param)=>handleNavBarClick(param, 'study')}*/}
                                {/*    />*/}
                                {/*</ListItem>*/}

                                <ListItem className={classes.listItem}>
                                    <CustomDropdown
                                        left
                                        caret={false}
                                        hoverColor="rose"
                                        dropdownHeader="User setting"
                                        buttonText={
                                            <AccountCircleIcon
                                                style={{
                                                    height: 35,
                                                    width: 35,
                                                    marginTop: 4,
                                                }}
                                            />
                                        }
                                        buttonProps={{
                                            className:
                                                classes.navLink +
                                                ' ' +
                                                classes.imageDropdownButton,
                                            color: 'transparent',
                                        }}
                                        dropdownList={['Profile', 'Sign out']}
                                        onClick={(param)=>handleNavBarClick(param)}

                                    />
                                </ListItem>

                                {/*<ListItem*/}
                                {/*    className={classes.listItem}*/}
                                {/*    style={{padding: '0 5px'}}*/}
                                {/*>*/}
                                {/*    <label>*/}
                                {/*        <Toggle*/}
                                {/*            icons={{*/}
                                {/*                checked: '🌛',*/}
                                {/*                unchecked: '🌞',*/}
                                {/*            }}*/}
                                {/*            onChange={onThemeChange}*/}
                                {/*        />*/}
                                {/*    </label>*/}
                                {/*</ListItem>*/}
                            </List>
                        }
                    />
                </div>
            </div>
        ) : user && user.usertype === 'admin' ? (
            <div className={classes.section}>
                <div id="navbar" className={classes.navbar}>
                    <Header
                        brand="&nbsp;"
                        Logo={Logo}
                        fixed={true}
                        // color={'dark'}
                        rightLinks={
                            <List className={classes.list}>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/app/admin'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Home
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/app/admin/posts'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Manage Posts
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/app/admin/users'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Manage Users
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/app/admin/settings'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Site Settings
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <CustomDropdown
                                        buttonText="My Pages"
                                        dropdownHeader="Government Jobs"
                                        buttonProps={{
                                            className: classes.navLink,
                                            color: 'transparent',
                                        }}
                                        hoverColor={'rose'}
                                        dropdownList={[
                                            'Home',
                                            'Results',
                                            'Railway',
                                            'Bank',
                                            'Defence',
                                            'Admit Card',
                                        ]}
                                        onClick={(param)=>handleNavBarClick(param, 'admin')}
                                    />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <CustomDropdown
                                        left
                                        caret={false}
                                        hoverColor="rose"
                                        dropdownHeader="User setting"
                                        buttonText={
                                            <AccountCircleIcon
                                                style={{
                                                    height: 35,
                                                    width: 35,
                                                    marginTop: 4,
                                                }}
                                            />
                                        }
                                        buttonProps={{
                                            className:
                                                classes.navLink +
                                                ' ' +
                                                classes.imageDropdownButton,
                                            color: 'transparent',
                                        }}
                                        dropdownList={['Profile', 'Sign out']}
                                        onClick={(param)=>handleNavBarClick(param)}


                                    />
                                </ListItem>
                                {/*<ListItem*/}
                                {/*    className={classes.listItem}*/}
                                {/*    style={{padding: '0 5px'}}*/}
                                {/*>*/}
                                {/*    <label>*/}
                                {/*        <Toggle*/}
                                {/*            defaultChecked={state.theme}*/}
                                {/*            icons={{*/}
                                {/*                checked: '🌞',*/}
                                {/*                unchecked: '🌛',*/}
                                {/*            }}*/}
                                {/*            onChange={onThemeChange}*/}
                                {/*        />*/}
                                {/*    </label>*/}
                                {/*</ListItem>*/}
                            </List>
                        }
                    />
                </div>
            </div>
        ) : (
            <div className={classes.section}>
                <div id="navbar" className={classes.navbar}>
                    <Header
                        brand="&nbsp;"
                        Logo={Logo}
                        fixed={true}
                        // color={"dark"}
                        rightLinks={
                            <List className={classes.list}>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Home
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/posts/results'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Result
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <CustomDropdown
                                        buttonText="Job Openings"
                                        dropdownHeader="Government Jobs"
                                        buttonProps={{
                                            className: classes.navLink,
                                            color: 'transparent',
                                        }}
                                        hoverColor={'rose'}
                                        dropdownList={[
                                            'Latest Jobs',
                                            {divider: true},
                                            'Railway',
                                            'Bank',
                                            'Defence',
                                            'Teaching',
                                            // { divider: true },
                                            //  "Admit Card",
                                            // { divider: true },
                                            // "Asnwer Key",
                                            // "Syllabus"
                                        ]}
                                        onClick={(param)=>handleNavBarClick(param, 'job')}
                                    />
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/posts/admit-cards'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Admit card
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.listItem}>
                                    <NavLink
                                        to={'/posts/answer-key'}
                                        color="transparent"
                                        className={classes.navLink}
                                    >
                                        Answer Key
                                    </NavLink>
                                </ListItem>
                                {/*<ListItem className={classes.listItem}>*/}
                                {/*    <CustomDropdown*/}
                                {/*        buttonText="Study Material"*/}
                                {/*        dropdownHeader="Government Jobs"*/}
                                {/*        buttonProps={{*/}
                                {/*            className: classes.navLink,*/}
                                {/*            color: 'transparent',*/}
                                {/*        }}*/}
                                {/*        hoverColor={'rose'}*/}
                                {/*        dropdownList={[*/}
                                {/*            'Railway',*/}
                                {/*            'Bank',*/}
                                {/*            'Defence',*/}
                                {/*            { divider: true },*/}
                                {/*            'Separated link',*/}
                                {/*            { divider: true },*/}
                                {/*            'One more separated link',*/}
                                {/*        ]}*/}
                                {/*        onClick={(param)=>handleNavBarClick(param, 'study')}*/}
                                {/*    />*/}
                                {/*</ListItem>*/}
                                <React.Fragment>
                                    <ListItem className={classes.listItem}>
                                        <NavLink
                                            to={'/login'}
                                            color="transparent"
                                            className={classes.navLink}
                                        >
                                            login
                                        </NavLink>
                                    </ListItem>
                                    <ListItem className={classes.listItem}>
                                        <CustomLink
                                            to={'/register'}
                                            className={classes.registerNavLink}
                                            color="rose"
                                            round
                                        >
                                            Register
                                        </CustomLink>
                                    </ListItem>
                                </React.Fragment>
                                <ListItem className={`${classes.listItem} search`}>
                                    <a
                                        href={'https://www.facebook.com/sarkariexamination'}
                                        color="transparent"
                                        className={classes.navLink}
                                        style={{padding: 4}}
                                        target="_blank"
                                    >
                                        <SearchIcon style={{ fontSize: 28 }} color="primary"/>
                                    </a>
                                </ListItem>
                                <ListItem className={`${classes.listItem} facebook`}>
                                    <a
                                        href={'https://www.facebook.com/sarkariexamination'}
                                        color="transparent"
                                        className={classes.navLink}
                                        style={{padding: 4}}
                                        target="_blank"
                                    >
                                        <FacebookIcon style={{ fontSize: 40 }} color="primary"/>
                                    </a>
                                </ListItem>
                                {/*<ListItem*/}
                                {/*    className={classes.listItem}*/}
                                {/*    style={{padding: '0 5px'}}*/}
                                {/*>*/}
                                {/*    <label>*/}
                                {/*        <Toggle*/}
                                {/*            icons={{*/}
                                {/*                checked: '🌛',*/}
                                {/*                unchecked: '🌞',*/}
                                {/*            }}*/}
                                {/*            onChange={onThemeChange}*/}
                                {/*        />*/}
                                {/*    </label>*/}
                                {/*</ListItem>*/}
                            </List>
                        }
                    />
                </div>
            </div>
        )

    return menuBar
}
