import React, { useState } from 'react'
import { Collapse } from 'antd'
import c from 'classnames'
import { Layout } from '../../../components'
import { PostForm, CategoryForm, HeaderForm, SchemaForm } from '../../index'
import NewsBoardList from './NewsBoardList'
import NewsForm from './NewsForm'
import SliderList from './SliderList'
import { Add, Settings, List } from '@material-ui/icons'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import './index.scss'
import PostList from './PostList'
import { Divider } from '@material-ui/core'

export default props => {
    const { Panel } = Collapse
    const [state, setState] = useState({
        isListVisible: false,
        isCreatePostVisible: false,
        isCateGoryFormVisible: false,
        isSliderPostVisible: true,
        isNewsBoardPostVisible: false,
        isNewsFormVisible: false,
    })
    const handlePostCreate = e => {
        e.preventDefault()
        setState({
            ...state,
            isSliderPostVisible: false,
            isNewsBoardPostVisible: false,
            isListVisible: false,
            isCreatePostVisible: true,
            isCateGoryFormVisible: false,
            isNewsFormVisible:false,
        })
    }
    const handleCategoryCreate = e => {
        e.preventDefault()
        setState({
            ...state,
            isSliderPostVisible: false,
            isNewsBoardPostVisible: false,
            isListVisible: false,
            isCreatePostVisible: false,
            isCateGoryFormVisible: true,
            isNewsFormVisible:false,
        })
    }
    const handleShowList = e => {
        e.preventDefault()
        setState({
            ...state,
            isSliderPostVisible: false,
            isNewsBoardPostVisible: false,
            isListVisible: true,
            isCreatePostVisible: false,
            isCateGoryFormVisible: false,
            isNewsFormVisible:false,
        })
    }
    const handleShowSlider = e => {
        e.preventDefault()
        setState({
            ...state,
            isSliderPostVisible: true,
            isNewsBoardPostVisible: false,
            isListVisible: false,
            isCreatePostVisible: false,
            isCateGoryFormVisible: false,
            isNewsFormVisible:false,
        })
    }
    const handleShowNewsBoard = e => {
        e.preventDefault()
        setState({
            ...state,
            isSliderPostVisible: false,
            isNewsBoardPostVisible: true,
            isListVisible: false,
            isCreatePostVisible: false,
            isCateGoryFormVisible: false,
            isNewsFormVisible:false,
        })
    }
    const handleShowNewsForm= e => {
        e.preventDefault()
        setState({
            ...state,
            isSliderPostVisible: false,
            isNewsBoardPostVisible: false,
            isListVisible: false,
            isCreatePostVisible: false,
            isCateGoryFormVisible: false,
            isNewsFormVisible:true,
        })
    }
    return (
        <Layout>
            <div className={'post-container'}>
                <div>
                    <h2>Manage posts</h2>
                    <div className={'control-panel'}>
                        <div
                            onClick={e => handleShowSlider(e)}
                            className={c({
                                active: state.isSliderPostVisible,
                            })}
                        >
                            <ViewCarouselIcon /> Slider Posts
                        </div>
                        <div
                            onClick={e => handleShowNewsBoard(e)}
                            className={c({
                                active: state.isNewsBoardPostVisible,
                            })}
                        >
                            <AnnouncementIcon /> News board
                        </div>
                        <div
                            onClick={e => handleShowList(e)}
                            className={c({
                                active: state.isListVisible,
                            })}
                        >
                            <List /> All Posts
                        </div>
                        <div
                            onClick={e => handlePostCreate(e)}
                            className={c({
                                active: state.isCreatePostVisible,
                            })}
                        >
                            <Add /> Create Post
                        </div>
                        <div
                            onClick={e => handleCategoryCreate(e)}
                            className={c({
                                active: state.isCateGoryFormVisible,
                            })}
                        >
                            <Add /> Create Category
                        </div>
                        <div
                            onClick={e => handleShowNewsForm(e)}
                            className={c({
                                active: state.isNewsFormVisible,
                            })}
                        >
                            <Add /> Submit News
                        </div>
                    </div>
                </div>
                <div>
                    {state.isListVisible && (
                        <div className={'post-panel'}>
                            <h1>Posts </h1>
                            <Divider />
                            <PostList />
                        </div>
                    )}
                    {state.isCreatePostVisible && (
                        <div className={'post-panel'}>
                            <h1>Create New Post</h1>
                            <Divider />
                            <Collapse defaultActiveKey={['1']}>
                                <Panel header="Create Post Form" key="1">
                                    {<PostForm />}
                                </Panel>

                                <Panel
                                    header="Create Header / Meta tag information for this post"
                                    key="3"
                                >
                                    {<HeaderForm />}
                                </Panel>
                                <Panel
                                    header="Create Schema / SEO meta tags"
                                    key="4"
                                >
                                    {<SchemaForm />}
                                </Panel>
                            </Collapse>
                        </div>
                    )}
                    {state.isCateGoryFormVisible && <CategoryForm />}
                    {state.isSliderPostVisible && (
                        <div className={'post-panel'}>
                            <h1>Slider List </h1>
                            <Divider />
                            <SliderList />
                        </div>

                    )}
                    {state.isNewsBoardPostVisible && (
                        <div className={'post-panel'}>
                            <h1>News Board List </h1>
                            <Divider />
                            <NewsBoardList />
                        </div>
                        )
                    }
                    {state.isNewsFormVisible && (
                        <div className={'post-panel'}>
                            <h1>News Board Form </h1>
                            <Divider />
                            <NewsForm />
                        </div>
                        )
                    }
                </div>
            </div>
        </Layout>
    )
}
