import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import {
    Divider,
    makeStyles,
    FormControlLabel,
    Switch,
    ButtonGroup,
} from '@material-ui/core'
import GridItem from '../../../../material-ui/components/Grid/GridItem'
import CustomInput from '../../../../material-ui/components/CustomInput/CustomInput'
import CustomButton from '../../../../components/Button'
import {
    CREATE_CATEGORY,
    EDIT_CATEGORY,
    DELETE_CATEGORY,
    GET_CATEGORY,
} from '../../../../apis/category'
import { useForm } from '../../../../utils/customHooks'
import FormHelperText from '@material-ui/core/FormHelperText'
import {Loader, Select} from '../../../../components'
import { CategoryFormColumn } from '../../../../utils/constant'
import { AlertBox } from '../../../../components'
import { Icon, Skeleton, Table } from 'antd'
import GridContainer from '../../../../material-ui/components/Grid/GridContainer'
import styles from '../../../../material-ui/assets/jss/material-kit-react/views/componentsSections/basicsStyle.js'
import SearchIcon from '@material-ui/icons/Search'
import { AuthContext } from '../../../../context/auth';
import './index.scss'
import {useToasts} from 'react-toast-notifications'
const useStyle = makeStyles(styles)
const { Column } = Table

export function CategoryForm() {
    const context = useContext(AuthContext);
    const { addToast } = useToasts()
    const classes = useStyle()
    const [visible, setVisible] = useState(true)
    const [errors, setErrors] = useState({})
    const [isEditMode, setEditMode] = useState(false)
    const [categoryId, setCategoryId] = useState('')
    const { loadingCategories, errorGet, data } = useQuery(GET_CATEGORY)

    //console.log("Data", data);
    const categoryData =
        data &&
        data.getCategories.map(item => {
            return {
                key: item.id,
                categoryTitle: item.categoryTitle,
                categoryType: item.categoryType && item.categoryType.typeCode,
                categoryLabel: item.categoryType && item.categoryType.typeLabel,
                visible: item.visible ? 'Yes' : 'No',
                order: item.order ? item.order : '',
            }
        })

    const handleDelete = record => {
        const { key } = record
        setCategoryId(key)
        AlertBox(
            'Delete Category',
            'Are you sure, you want to delete this category?',
            deleteCategory
        )
    }
    const { onChange, onSubmit, onFillValue, values } = useForm(categoryAction, {
        categoryId: '',
        categoryTitle: '',
        order: '',
        categoryType: '',
        categoryLabel: '',
        filter: '',
        catTypeArray: [
            {
                value: 1,
                text: 'Category',
            },
            {
                value: 2,
                text: 'Education',
            },
            {
                value: 3,
                text: 'Location',
            },
            {
                value: 4,
                text: 'Tag',
            },
        ],
    })

    const [addCategory, { addError , loading }] = useMutation(CREATE_CATEGORY, {
        variables: {
            categoryTitle: values.categoryTitle,
            order: parseInt(values.order),
            categoryType: {
                typeCode: values.categoryType,
                typeLabel: values.categoryLabel,
            },
            visible,
        },
        update(proxy, result) {
            const data = proxy.readQuery({
                query: GET_CATEGORY,
            })
            data.getCategories = [
                result.data.createCategory,
                ...data.getCategories,
            ]
            proxy.writeQuery({ query: GET_CATEGORY, data })
            values.categoryTitle = ''
            //values.categoryType = ''
            values.order = ''
            addToast('New Category added successfully.', {
                appearance: 'success',
                autoDismiss: true,
            })
            setErrors({})
        },
        onError(err) {
            console.log('Error', err.graphQLErrors[0].extensions)
            addToast(`${err.graphQLErrors[0].message}`, {
                appearance: 'error',
                autoDismiss: true,
            })
            setErrors(
                err.graphQLErrors[0] && err.graphQLErrors[0].extensions.errors
            )
            // if(err.graphQLErrors[0] && err.graphQLErrors[0].extensions)
        },
    })

    const [editCategory, { editError, loading: editLoading }] = useMutation(EDIT_CATEGORY, {
        variables: {
            categoryId: values.categoryId,
            categoryTitle: values.categoryTitle,
            order: parseInt(values.order),
            categoryType: {
                typeCode: values.categoryType,
                typeLabel: values.categoryLabel,
            },
            visible,
        },
        update(proxy, result) {
            values.categoryTitle = ''
            values.categoryType = ''
            values.order = '';

            addToast('Category Edited successfully.', {
                appearance: 'success',
                autoDismiss: true,
            })
            setErrors({})
        },
        onError(err) {

            console.log('Error', err)
            addToast(`${err.graphQLErrors[0].message}`, {
                appearance: 'error',
                autoDismiss: true,
            })
            if (
                err.graphQLErrors[0] &&
                err.graphQLErrors[0].extensions &&
                err.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
            ) {
                context.logout()
            }
            setErrors(
                err.graphQLErrors[0] && err.graphQLErrors[0].extensions.errors
            )
        },
    })

    const [deleteCategory, { deleteError, loading: delLoading }] = useMutation(DELETE_CATEGORY, {
        variables: {
            categoryId,
        },
        update(proxy, result) {
            const data = proxy.readQuery({
                query: GET_CATEGORY,
            })
            if (data && data.getCategories) {
                data.getCategories = data.getCategories.filter(
                    item => item.id !== categoryId
                )
            }
            // console.log("data", data)
            proxy.writeQuery({ query: GET_CATEGORY, data })
            addToast('Category deleted successfully.', {
                appearance: 'success',
                autoDismiss: true,
            })
            setErrors({})
        },
        onError(err) {
            console.log('Error', err)
            addToast(`${err.graphQLErrors[0].message}`, {
                appearance: 'error',
                autoDismiss: true,
            })
            if(err.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
                context.logout()
            }
            setErrors(
                err.graphQLErrors[0] && err.graphQLErrors[0].extensions.errors
            )
        },
    })
    useEffect(()=>{
        if(values.categoryType !== "") {
            onChange("categoryLabel", values.categoryType !== "" && values.catTypeArray[values.categoryType - 1].text)
        }
    },[values.categoryType])

    function categoryAction() {
        if(isEditMode) {
            editCategory()
        } else {
            addCategory()
        }
    }

    const handleEditAction = useCallback(async (record) => {
        setEditMode(true)
        const fillForm = {
            categoryId: record.key,
            categoryType : record.categoryType,
            categoryTitle : record.categoryTitle,
            visible : record.visible.toLowerCase() === 'yes',
            order: record.order
        }
        onFillValue(fillForm)
    },[values])

    const handleChange = (fieldFor, value) => {
        if(value !== 1) {
            setVisible(false)
        }
        onChange(fieldFor, value)

    }

    const handleCancelClick = () => {
        setEditMode(false)
        values.categoryTitle = ''
        values.categoryType = ''
        values.order = ''
    }

    const validateForm = () => {}
    return (
        <div>
            {(loading || editLoading || delLoading || loadingCategories) && <Loader /> }
            <div className={'category-panel'}>
                <h1>{isEditMode && 'Edit'} Category</h1>
                <Divider />
                <form className={classes.form} onSubmit={onSubmit}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={3}
                            lg={3}
                            className={'row'}
                        >
                            <Select
                                id={'categoryType'}
                                fieldFor={'categoryType'}
                                value={values.categoryType}
                                onChange={handleChange}
                                listArray={values.catTypeArray}
                                label={'categoryType'}
                                customClass={'category-select'}
                            />
                        </GridItem>
                        {values.categoryType !== '' && (
                            <GridItem
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                className={'row'}
                            >
                                <CustomInput
                                    labelText={
                                        values.catTypeArray[
                                            values.categoryType - 1
                                        ].text + ' name'
                                    }
                                    id="categoryTitle"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        type: 'text',
                                        autoComplete: 'off',
                                        value: values.categoryTitle,
                                        error: errors && !!errors.category,
                                        onChange: e =>
                                            onChange(
                                                'categoryTitle',
                                                e.target.value
                                            ),
                                    }}
                                    require
                                />
                                {errors && !!errors.category && (
                                    <FormHelperText error={!!errors.category}>
                                        {errors && errors.category}{' '}
                                    </FormHelperText>
                                )}
                            </GridItem>
                        )}
                        {values.categoryType === 1 && (
                            <React.Fragment>
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    className={'row'}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={visible}
                                                onChange={event =>
                                                    setVisible(
                                                        event.target.checked
                                                    )
                                                }
                                                value="visible"
                                                classes={{
                                                    switchBase:
                                                        classes.switchBase,
                                                    checked:
                                                        classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar,
                                                }}
                                                disabled={!values.categoryTitle}
                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                        }}
                                        label="Make Category Visible"
                                        className={'switch'}
                                    />
                                </GridItem>

                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    className={'row'}
                                >
                                    <CustomInput
                                        labelText="Order"
                                        id="order"
                                        formControlProps={{
                                            fullWidth: true,
                                            disabled: !visible,
                                        }}
                                        inputProps={{
                                            type: 'text',
                                            autoComplete: 'off',
                                            value: values.order,
                                            error: errors && !!errors.order,
                                            onChange: e =>
                                                onChange(
                                                    'order',
                                                    e.target.value
                                                ),
                                        }}
                                    />
                                    {errors && !!errors.order && (
                                        <FormHelperText error={!!errors.order}>
                                            {errors.order}
                                        </FormHelperText>
                                    )}
                                </GridItem>
                            </React.Fragment>
                        )}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <ButtonGroup>
                                <CustomButton
                                    className={'button-submit'}
                                    color={'rose'}
                                    children={'Submit'}
                                    type={'submit'}
                                    disabled={!values.categoryTitle || !values.categoryType}
                                />
                                <CustomButton
                                    className={'button-cancel'}
                                    color={'default'}
                                    children={'Cancel'}
                                    onClick={handleCancelClick}
                                />
                            </ButtonGroup>
                        </GridItem>
                    </GridContainer>
                </form>

                <GridContainer>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        className={'filter'}
                    >
                        <CustomInput
                            className={classes.input}
                            labelText="Filter"
                            id="filter"
                            formControlProps={{
                                fullWidth: true,

                            }}
                            inputProps={{
                                type: 'text',
                                autoComplete: 'off',
                                value: values.filter,
                                error: errors && !!errors.order,
                                onChange: e =>
                                    onChange('filter', e.target.value),
                            }}
                        />
                    </GridItem>
                </GridContainer>
                {!loadingCategories ? (
                    <Table dataSource={data && categoryData}>
                        <Column
                            title="Category Name"
                            dataIndex="categoryTitle"
                            width={'30%'}
                            key="categoryTitle"
                            sorter={(a, b) => {
                                const bandA = a.categoryTitle.toUpperCase()
                                const bandB = b.categoryTitle.toUpperCase()

                                let comparison = 0
                                if (bandA > bandB) {
                                    comparison = 1
                                } else if (bandA < bandB) {
                                    comparison = -1
                                }
                                return comparison
                            }}
                        />
                        <Column
                            title="Type"
                            dataIndex="categoryLabel"
                            width={'20%'}
                            key="categoryLabel"
                            defaultFilteredValue={["Category"]}
                            filters={[
                                {
                                    value: 'Category',
                                    text: 'Category',
                                },
                                {
                                    value: 'Education',
                                    text: 'Education',
                                },
                                {
                                    value: 'Location',
                                    text: 'Location',
                                },
                                {
                                    value: 'Tag',
                                    text: 'Tag',
                                },
                            ]}
                            filterMultiple={false}
                            onFilter={(value, record) =>
                                record && record.categoryLabel && record.categoryLabel.indexOf(value) === 0
                            }
                            sorter={(a, b) => {
                                console.log("a", a)
                                console.log("b", b)
                                return a.address.length - b.address.length
                            }
                            }
                            sortDirections={['descend', 'ascend']}
                        />
                        <Column
                            title="Visible"
                            dataIndex="visible"
                            width={'20%'}
                            key="visible"
                        />
                        <Column
                            title="Order"
                            width={'20%'}
                            dataIndex="order"
                            key="order"
                            sorter={true}
                        />
                        <Column
                            title=""
                            key="delete"
                            width={'10%'}
                            render={(text, record) => (
                                <span>
                                    <span
                                        className={'edit'}
                                        onClick={() => handleEditAction(record)}
                                    >
                                        <Icon type="edit" />
                                    </span>
                                    <span
                                        className={'delete'}
                                        onClick={() => handleDelete(record)}
                                    >
                                        <Icon type="delete" />
                                    </span>
                                </span>
                            )}
                        />
                    </Table>
                ) : (
                    <Skeleton
                        active
                        loading={loadingCategories}
                        paragraph={{ rows: 5 }}
                    />
                )}
            </div>
        </div>
    )
}
