import * as React from 'react'
import {Layout, Slider, Welcome} from '../../components'

export const Contact = (props) => {
    return (
        <Layout>
            <Welcome/>
            <Slider/>
            This will be contact form.
        </Layout>
    )
}
