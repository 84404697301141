import React, {Component} from 'react'

class Editor extends Component {
    render() {
        return (
            <div>
                Editor example
            </div>
        )
    }
}

export default Editor
