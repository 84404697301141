import * as React from 'react'
import {Layout} from '../../../../components'

export const SchemaForm = (props) => {
    return (
        <div>
            This is a Schema form for Setting Meta tags for a post.
        </div>
    )
}
