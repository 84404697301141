import React from 'react'
import {Route, Switch} from 'react-router-dom'
import { ThemeProvider} from '@material-ui/core/styles';
import PrivateRoute from './PrivateRoute'
import Login from './pages/Login'
import Register from './pages/Register'
import Home from './pages/Home'
import Contact from './pages/Contact'
import About from './pages/About'
import PageNotFound from './pages/PageNotFound'
import Admission from './pages/Posts/Admission'
import AdmitCard from './pages/Posts/AdmitCard'
import AnswerKey from './pages/Posts/AnswerKey'
import Bank from './pages/Posts/Bank'
import LatestJobs from './pages/Posts/LatestJobs'
import Defence from './pages/Posts/Defence'
import Railway from './pages/Posts/Railway'
import Result from './pages/Posts/Result'
import Admin from './pages/Admin'
import Posts from './pages/Admin/Post'
import CategoryForm from './pages/Admin/Post/CategoryForm'
import HeaderForm from './pages/Admin/Post/HeaderForm'
import PostForm from './pages/Admin/Post/PostForm'
import SchemaForm from './pages/Admin/Post/SchemaForm'
import User from './pages/Admin/User'
import UserForm from './pages/Admin/User/UserForm'
import Post from './pages/Posts/Post'
import SingleUser from './pages/Admin/User/SingleUser'
import SiteSetting from "./pages/Admin/SiteSetting"

import "./styles/main.scss"


const Routes = (props) => (
    <Switch>
        <Route exact path="/" component={Home} {...props}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/register" component={Register}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/contact" component={Contact}/>

        {/*<Route exact path="/posts/railway" component={Railway}/>*/}
        {/*<Route exact path="/posts/result" component={Result}/>*/}
        {/*<Route exact path="/posts/bank" component={Bank}/>*/}
        {/*<Route exact path="/posts/latestjobs" component={LatestJobs}/>*/}
        {/*<Route exact path="/posts/defence" component={Defence}/>*/}
        {/*<Route exact path="/posts/admitcard" component={AdmitCard}/>*/}
        {/*<Route exact path="/posts/admission" component={Admission}/>*/}
        {/*<Route exact path="/posts/answerkey" component={AnswerKey}/>*/}

        <Route exact path="/posts/railway" component={PageNotFound}/>
        <Route exact path="/posts/results" component={Result}/>
        <Route exact path="/posts/latest-jobs" component={LatestJobs}/>
        <Route exact path="/posts/banking-jobs" component={PageNotFound}/>
        <Route exact path="/posts/banking-jobs" component={PageNotFound}/>
        <Route exact path="/posts/teaching-jobs" component={PageNotFound}/>
        <Route exact path="/posts/defence" component={PageNotFound}/>
        <Route exact path="/posts/admit-cards" component={AdmitCard}/>
        <Route exact path="/posts/admission" component={PageNotFound}/>
        <Route exact path="/posts/answer-key" component={PageNotFound}/>

        <Route exact path="/posts/railway/:id" component={Post}/>
        <Route exact path="/posts/:id" component={Post} {...props}/>
        <Route exact path="/posts/blog/:id" component={Post} {...props}/>
        <Route exact path="/blog/:id" component={Post} {...props}/>
        <Route exact path="/posts/results/:id" component={Post}/>
        <Route exact path="/posts/banking-jobs/:id" component={Post}/>
        <Route exact path="/posts/teaching-jobs/:id" component={PageNotFound}/>

            <Route
            exact
            path="/posts/latest-jobs/:id"
            component={Post}
        />
        <Route
            exact
            path="/posts/admit-cards/:id"
            component={Post}
        />
        <Route
            exact
            path="/posts/admission/:id"
            component={Post}
        />
        <Route
            exact
            path="/posts/answer-key/:id"
            component={Post}
        />

        <PrivateRoute exact path="/app/admin" component={Admin}/>
        <PrivateRoute exact path="/app/admin/posts" component={Posts}/>
        <PrivateRoute exact path="/app/admin/users" component={User}/>
        <PrivateRoute exact path="/app/admin/post/category-form"  component={CategoryForm}/>
        <PrivateRoute exact path="/app/admin/settings"  component={SiteSetting}/>
        <PrivateRoute exact path="/app/admin/post/header-form" component={HeaderForm}/>
        <PrivateRoute exact path="/app/admin/post/post-form" component={PostForm}/>
        <PrivateRoute exact path="/app/admin/post/schema-form" component={SchemaForm}/>
        <PrivateRoute exact path="/app/admin/user/user-form" component={UserForm}/>
        <PrivateRoute exact path="/app/admin/user/:id" component={SingleUser}/>

        <Route component={PageNotFound}/>
    </Switch>
)

export default Routes
