import React from 'react'
import LinkIcon from '@material-ui/icons/Link';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import "./attachments.scss"

const Attachments = (props) => {
    const { attachments } = props;
    return (
        <div className="attachments" id="attachment">
            <h2 className={'header'}>Resources + Attachments</h2>
            {
                attachments && attachments.length> 0 && attachments.map((item, index) => {
                    const { jobSourceName, linkLabel, linkUrl,  urlType } = item
                    return (
                        <div className="url-container" key={index}>
                            <div className="url-name">{urlType === 1 ? (<LinkIcon type="link" />) : (<PictureAsPdfIcon type="file-pdf" />)} { jobSourceName }</div>
                            <div className="url"><a  href={linkUrl} target="_blank">{linkLabel}</a></div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Attachments
