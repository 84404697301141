import {container, title} from "../../../material-kit-react.js"
import customCheckboxRadioSwitch from "../../../material-kit-react/customCheckboxRadioSwitch.js"

const basicsStyle = {

    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    sections: {
        padding: "70px 0"
    },
    container,
    title: {
        ...title,
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none"
    },
    space50: {
        height: "50px",
        display: "block"
    },
    space70: {
        height: "70px",
        display: "block"
    },
    icons: {
        width: "17px",
        height: "17px",
        color: "#FFFFFF"
    },
    form : {
       marginTop: "20px"
    },
    ...customCheckboxRadioSwitch
}

export default basicsStyle
