import * as React from 'react'
import {Layout, Slider, Welcome} from '../../../components'
import GridContainer from '../../../material-ui/components/Grid/GridContainer'
import GridItem from '../../../material-ui/components/Grid/GridItem'
import NavPills from '../../../material-ui/components/NavPills/NavPills'
import {Dashboard, Schedule} from '@material-ui/icons'

export const AdmitCard = (props) => {
    console.log("Admit card page")
    return (
        <Layout>
            <Welcome/>
            <Slider/>
            This page will hold the admitcard posts.
            <div className={' post-panel'}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <NavPills
                            color="rose"
                            className={'nav-pills'}
                            horizontal={{
                                tabsGrid: {xs: 12, sm: 4, md: 4},
                                contentGrid: {xs: 12, sm: 8, md: 8},
                            }}
                            tabs={[
                                {
                                    tabButton: 'Admit Cards',
                                    tabIcon: Dashboard,
                                    tabContent: (
                                        <span>
                                            <p>
                                                Collaboratively administrate empowered markets via
                                                plug-and-play networks. Dynamically procrastinate
                                                B2C users after installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <p>
                                                Collaboratively administrate empowered markets via
                                                plug-and-play networks. Dynamically procrastinate
                                                B2C users after installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <p>
                                                Collaboratively administrate empowered markets via
                                                plug-and-play networks. Dynamically procrastinate
                                                B2C users after installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <p>
                                                Collaboratively administrate empowered markets via
                                                plug-and-play networks. Dynamically procrastinate
                                                B2C users after installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <p>
                                                Collaboratively administrate empowered markets via
                                                plug-and-play networks. Dynamically procrastinate
                                                B2C users after installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <p>
                                                Collaboratively administrate empowered markets via
                                                plug-and-play networks. Dynamically procrastinate
                                                B2C users after installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <p>
                                                Collaboratively administrate empowered markets via
                                                plug-and-play networks. Dynamically procrastinate
                                                B2C users after installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <p>
                                                Collaboratively administrate empowered markets via
                                                plug-and-play networks. Dynamically procrastinate
                                                B2C users after installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <p>
                                                Collaboratively administrate empowered markets via
                                                plug-and-play networks. Dynamically procrastinate
                                                B2C users after installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                            <br/>
                                            <p>
                                                Dramatically visualize customer directed convergence
                                                without revolutionary ROI. Collaboratively
                                                administrate empowered markets via plug-and-play
                                                networks. Dynamically procrastinate B2C users after
                                                installed base benefits.
                                            </p>
                                        </span>
                                    ),
                                },
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </Layout>
    )
}
