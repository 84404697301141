import * as React from 'react'
import {Layout} from "../../components"

export default () => {
    return (
        <Layout>
            This is a default Admin page.
        </Layout>
    )
}
