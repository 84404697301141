import * as React from 'react'
import {Layout} from '../../../../components'

export const HeaderForm = (props) => {
    return (
        <div>
            This is a form for setting Meta tags in the header for a given post.
        </div>
    )
}
