/*eslint-disable*/
import React from "react"
import {Link} from "react-router-dom"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// nodejs library that concatenates classes
import classNames from "classnames"
// material-ui core components
import {List, ListItem} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"

import styles from "../../assets/jss/material-kit-react/components/footerStyle.js"

const useStyles = makeStyles(styles)

export default function Footer(props) {
    const classes = useStyles()
    const {whiteFont} = props
    const footerClasses = classNames({
        [classes.footer]: true,
        [classes.footerWhiteFont]: whiteFont
    })
    const aClasses = classNames({
        [classes.a]: true,
        [classes.footerWhiteFont]: whiteFont
    })
    return (
        <footer className={footerClasses}>
            <div className={classes.container}>
                <div className={classes.left}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <Link
                                to={"/about"}
                                className={classes.block}
                                target="_blank"
                            >
                                About us
                            </Link>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <Link
                                to={"/contact"}
                                className={classes.block}
                                target="_blank"
                            >
                                Contact Us
                            </Link>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <Link
                                to={"/licence"}
                                className={classes.block}
                                target="_blank"
                            >
                                Licenses
                            </Link>
                        </ListItem>
                    </List>
                </div>
                <div className={classes.right}>
                    &copy;{1900 + new Date().getYear()} , made with{" "}
                    💗 by{" "}
                    <a
                        href="mailto: rvndnishad.work@gmail.com"
                        className={aClasses}
                        target="_blank"
                    >
                        Sarkariexam.in
                    </a>
                </div>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    whiteFont: PropTypes.bool
}
