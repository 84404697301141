import {useState} from 'react'

export const useForm = (callback, initialState = {}) => {
    const [values, setValues] = useState(initialState)

    const onChange = (field, value) => {
        setValues({...values, [field]: value})
    }
    const onSelect = (field, value) => {
        setValues({...values,  [field]: value})
    }

    const resetForm = (initialState) => {
        setValues({...values, ...initialState})
    }

    const onSubmit = event => {
        event.preventDefault()
        callback()
    }

    const onFillValue = (props) => {
            setValues({
                ...values,
                categoryId: props.categoryId,
                categoryType: props.categoryType,
                categoryTitle: props.categoryTitle,
                visible: props.visible,
                order: props.order,
            })

    }

    return {
        onChange,
        onSubmit,
        onSelect,
        onFillValue,
        resetForm,
        values,
    }
}
