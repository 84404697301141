import React, {useState} from 'react'
import {FormControlLabel, makeStyles, Switch} from '@material-ui/core'
import styles from '../../../../material-ui/assets/jss/material-kit-react/views/componentsSections/basicsStyle.js'
const useStyle = makeStyles(styles)

export const SingleNewsBoard = (props) => {
    const [newBoardSwitch, setNesBoardSwitch] = useState(props.post.showInNewsBoard)
    const classes = useStyle()
    const {
        index,
        post: {
            id,
            showInNewsBoard,
            newsBoardHeading,
            category,
            date: {
                postCreatedAt
            }
        }
    } = props

    const onChange = (field, value) => {
        setNesBoardSwitch(value);

    }

    return (
        <div className={'row row-body'}>
            <div>{index}</div>
            <div>
                {category.length > 0 &&
                category.map((tag, index) => (
                    <span key={index}>{tag.name}</span>
                ))}
            </div>
            <div className={'post-heading'}>{newsBoardHeading}</div>
            <div className={'post-created'}>{postCreatedAt}</div>
            <div>
                <FormControlLabel
                    control={
                        <Switch
                            size={'small'}
                            checked={newBoardSwitch}
                            onChange={event =>
                            {
                                onChange('newBoardSwitch', event.target.checked)
                            }
                            }
                            name={'newBoardSwitch'}
                            value="newBoardSwitch"
                            classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar,
                            }}

                        />
                    }
                    classes={{
                        label: classes.label,
                    }}
                    label={''}
                    className={'switch'}
                />
            </div>
        </div>
    )

}
