import React, { useEffect } from 'react'
import {Helmet} from 'react-helmet'
import { useQuery, useMutation } from 'react-apollo'
import { ToastProvider } from "react-toast-notifications"
import { Comment, Layout, Loader, Slider, Welcome } from '../../../components'
import {
    GET_ALL_POSTS_SLIDER,
    GET_SINGLE_POST_ALL_FIELD,
    UPDATE_VIEW,
} from '../../../apis/posts'
import { Dates } from './Date'
import Share from './Share'
import Sidebar from '../../../components/Sidebar/Sidebar'
import { showAds } from '../../../utils/constant'
import GridContainer from '../../../material-ui/components/Grid/GridContainer'
import GridItem from '../../../material-ui/components/Grid/GridItem'
import Attachments from './Attachments'
import JobOffer from "../../../assets/Exploding-Job-Offer-Dice.png"
import './postCss.scss'

export const Post = props => {
    const id = props.location.pathname.split('_')[1]
    // console.log('id', id)
    const [
        updateView,
        { error: updateViewError, loading: updateViewLoading },
    ] = useMutation(UPDATE_VIEW, {
        variables: {
            postId: id,
        },
        update(proxy, result) {
            console.log(result)
        },
        onError: err => {
            console.log('err', err)
            setErrors(err.graphQLErrors[0].extensions.errors)
        },
    })

    const {
        loading: sliderLoading,
        error: sliderError,
        data: sliderData,
    } = useQuery(GET_ALL_POSTS_SLIDER, {
        variables: { showInSlider: true },
    })

    const { loading: postLoading, error: postError, data: postData } = useQuery(
        GET_SINGLE_POST_ALL_FIELD,
        {
            variables: { postId: id },
        }
    )
    useEffect(() => {
        updateView()
    }, [])
    const { getPost } = !postLoading && postData
    return (
        <Layout>
            <Helmet>
                <title>{getPost && getPost.postHeading}</title>
                <meta
                    name="Description"
                    content={getPost && getPost.postDescription}
                />
                <link
                    rel="canonical"
                    href={typeof window !== 'undefined' && window.location}
                />
                <meta
                    name="robots"
                    content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <meta
                    itemProp="image"
                    content="https://storage.googleapis.com/sarkariexam/images/Exploding-Job-Offer-Dice.png"
                />
                <meta
                    property="og:image"
                    content="https://storage.googleapis.com/sarkariexam/images/Exploding-Job-Offer-Dice.png"
                />
                <meta
                    property="og:image:secure_url"
                    content="https://storage.googleapis.com/sarkariexam/images/Exploding-Job-Offer-Dice.png"
                />
            </Helmet>
            <Welcome />
            {sliderLoading ? (
                <h2>Loading ...</h2>
            ) : (
                <Slider data={sliderData} loading={sliderLoading} />
            )}
            {updateViewLoading || postLoading ? (
                <Loader />
            ) : (
                <div className="post-page">
                    {showAds && (
                        <GridContainer className={'ad-space'}>
                            <GridItem xs={12} sm={10} md={10} lg={10}>
                                <ins
                                    className="adsbygoogle"
                                    data-ad-client="ca-pub-1422138996530336"
                                    data-ad-slot="5065958412"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"
                                />
                            </GridItem>
                        </GridContainer>
                    )}
                    <div className="slidePost">
                        <div className="postContainer">
                            {getPost && getPost.postHeading && (
                                <header>
                                    <h1 className={'post-heading'}>
                                        {getPost && getPost.postHeading}
                                    </h1>
                                </header>
                            )}
                            <main>
                                <section>
                                    <Share post={getPost} />
                                </section>
                                {getPost && getPost.postDescription && (
                                    <section>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    getPost &&
                                                    getPost.postDescription,
                                            }}
                                            className="description"
                                        />
                                    </section>
                                )}

                                <section>
                                    <div className="date-container">
                                        <Dates date={getPost && getPost.date} />
                                    </div>
                                </section>
                                <section>
                                    {showAds && (
                                        <GridContainer className={'ad-space'}>
                                            <GridItem
                                                xs={12}
                                                sm={10}
                                                md={10}
                                                lg={10}
                                            >
                                                <ins
                                                    className="adsbygoogle"
                                                    data-ad-client="ca-pub-1422138996530336"
                                                    data-ad-slot="3809874617"
                                                    data-ad-format="auto"
                                                    data-full-width-responsive="true"
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    )}
                                </section>
                                {getPost && getPost.body && (
                                    <section>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: getPost && getPost.body,
                                            }}
                                            className="body"
                                        />
                                    </section>
                                )}

                                {getPost &&
                                    getPost.attachment &&
                                    getPost.attachment.length > 0 && (
                                        <section>
                                            <Attachments
                                                attachments={
                                                    getPost &&
                                                    getPost.attachment
                                                }
                                            />
                                        </section>
                                    )}
                                <section>
                                    <footer>
                                        <h2>
                                            😇 🕺 Thank you visiting this site.
                                            👯😇
                                        </h2>
                                    </footer>
                                </section>
                                {/*<footer>More jobs panel with more jobs</footer>*/}
                                {showAds && (
                                    <GridContainer className={'ad-space'}>
                                        <GridItem
                                            xs={12}
                                            sm={10}
                                            md={10}
                                            lg={10}
                                        >
                                            <ins
                                                className="adsbygoogle"
                                                data-ad-client="ca-pub-1422138996530336"
                                                 data-ad-slot="5525764044"
                                                data-ad-format="auto"
                                                data-full-width-responsive="true"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                )}
                                {getPost &&
                                    getPost.comments &&
                                    getPost.comments.length > 0 && (
                                        <section>
                                            <div className="comment-container">
                                                <h2>Comments</h2>
                                                {getPost.comments.map(item => {
                                                    return (
                                                        <Comment
                                                            comment={item}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </section>
                                    )}

                                <section className="disclamer">
                                    Disclaimer : The Examination Results / Marks
                                    published in this Website is only for the
                                    immediate Information to the Examinees an
                                    does not to be a constitute to be a Legal
                                    Document. While all efforts have been made
                                    to make the Information available on this
                                    Website as Authentic as possible. We are not
                                    responsible for any Inadvertent Error that
                                    may have crept in the Examination Results /
                                    Marks being published in this Website nad
                                    for any loss to anybody or anything caused
                                    by any Shortcoming, Defect or Inaccuracy of
                                    the Information on this Website.
                                </section>
                            </main>
                        </div>
                        <section className="sidebar">
                            <ToastProvider>
                                <Sidebar post={getPost} />
                            </ToastProvider>
                        </section>
                    </div>
                </div>
            )}
        </Layout>
    )
}
