import Routes from '../Routes'
import { BrowserRouter } from 'react-router-dom'
import React, {useEffect} from 'react'
import ReactGA from 'react-ga'
import { hydrate } from 'react-dom'
import { ApolloProvider } from 'react-apollo'
import { ThemeProvider } from '@material-ui/core/styles'
import { createClient } from '../lib/apollo'
import { AuthProvider } from '../context/auth'
import theme from '../theme'
import Particles from 'react-particles-js'
import createHistory from 'history/createBrowserHistory'
import Sidebar from '../components/Sidebar/Sidebar'
import { ToastProvider } from 'react-toast-notifications'
const client = createClient()

const history = createHistory();
history.listen(location => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
})

function Main() {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname)
    },[])
    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <ToastProvider>
                    <AuthProvider>
                        <BrowserRouter>
                            <Particles
                                canvasClassName={'particle'}
                                style={{
                                    display: 'block',
                                    position: 'absolute',
                                }}
                                params={{
                                    particles: {
                                        number: {
                                            value: 150,
                                        },
                                        size: {
                                            value: 5,
                                        },
                                    },
                                    interactivity: {
                                        events: {
                                            onhover: {
                                                enable: false,
                                                mode: ['bubble'],
                                            },
                                            onclick: {
                                                enable: false,
                                                mode: 'repulse',
                                            },
                                        },
                                    },
                                }}
                            />
                            <Routes history={history} />
                        </BrowserRouter>
                    </AuthProvider>
                </ToastProvider>
            </ThemeProvider>
        </ApolloProvider>
    )
}

hydrate(<Main />, document.getElementById('root'), () => {
    const jssStyles = document.getElementById('jss-ssr')
    if (jssStyles && jssStyles.parentNode)
        jssStyles.parentNode.removeChild(jssStyles)
})

if (module.hot) {
    module.hot.accept()
}
