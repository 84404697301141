import React, { useState, useContext } from 'react';
import { Layout, Loader, SEO } from '../../components';
import gql from 'graphql-tag';
import { useMutation, withApollo } from 'react-apollo';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import FormHelperText from '@material-ui/core/FormHelperText';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import People from '@material-ui/icons/People';
// core components
import GridContainer from '../../material-ui/components/Grid/GridContainer.js';
import GridItem from '../../material-ui/components/Grid/GridItem.js';
import Button from '../../material-ui/components/CustomButtons/Button.js';
import Card from '../../material-ui/components/Card/Card.js';
import CardBody from '../../material-ui/components/Card/CardBody.js';
import CardHeader from '../../material-ui/components/Card/CardHeader.js';
import CardFooter from '../../material-ui/components/Card/CardFooter.js';
import CustomInput from '../../material-ui/components/CustomInput/CustomInput.js';
import { useForm } from '../../utils/customHooks';
import styles from '../../material-ui/assets/jss/material-kit-react/views/loginPage.js';
import image from '../../assets/sign.jpg';
import { REGISTE_USER } from '../../apis/users';
import { AuthContext } from '../../context/auth';

const useStyles = makeStyles(styles);
const Register = props => {
    const context = useContext(AuthContext);
    const [errors, setErrors] = useState({});

    const { onChange, onSubmit, values } = useForm(registerUser, {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
    setTimeout(function() {
        setCardAnimation('');
    }, 700);
    const classes = useStyles();

    const [addUser, { loading }] = useMutation(REGISTE_USER, {
        update(_, { data: { register: userData } }) {
            context.login(userData);
            props.history.push("/")
        },
        onError(err) {
            setErrors(err.graphQLErrors[0].extensions.errors);
        },
        variables: {
            username: values.email,
            email: values.email,
            password: values.password,
        },
    });

    function registerUser() {
        addUser();
    }

    return (
        <div className={'register-page'}>
            <Layout>
                <div
                    className={classes.pageHeader}
                    style={{
                        backgroundImage: 'url(' + image + ')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                    }}
                >
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={4}>
                                <Card className={classes[cardAnimaton]}>
                                    <form className={classes.form} onSubmit={onSubmit}>
                                        <CardHeader color="rose" className={classes.cardHeader}>
                                            <h4>Register</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <CustomInput
                                                labelText="Name..."
                                                id="name"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <People
                                                                className={classes.inputIconsColor}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    autoComplete: 'off',
                                                    value: values.name,
                                                    onChange: e => onChange('name', e.target.value),
                                                    ['aria-describedby']: 'component-error-text',
                                                    error: !!errors.username,
                                                }}
                                            />
                                            {!!errors.username && (
                                                <FormHelperText
                                                    error={!!errors.username}
                                                    id="component-error-text"
                                                >
                                                    {errors.username}
                                                </FormHelperText>
                                            )}
                                            <CustomInput
                                                labelText="Email..."
                                                id="email"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'email',
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Email
                                                                className={classes.inputIconsColor}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    autoComplete: 'off',
                                                    value: values.email,
                                                    onChange: e => onChange('email', e.target.value),
                                                    ['aria-describedby']: 'component-error-text',
                                                    error: !!errors.email,
                                                }}
                                            />
                                            {!!errors.email && (
                                                <FormHelperText
                                                    error={!!errors.email}
                                                    id="component-error-text"
                                                >
                                                    {errors.email}
                                                </FormHelperText>
                                            )}

                                            <CustomInput
                                                labelText="Password"
                                                id="password"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'password',
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LockIcon />
                                                        </InputAdornment>
                                                    ),
                                                    autoComplete: 'off',
                                                    value: values.password,
                                                    onChange: e => onChange('password', e.target.value),
                                                    ['aria-describedby']: 'component-error-text',
                                                    error: !!errors.password,
                                                }}
                                            />
                                            {!!errors.password && (
                                                <FormHelperText
                                                    error={!!errors.password}
                                                    id="component-error-text"
                                                >
                                                    {errors.password}
                                                </FormHelperText>
                                            )}
                                        </CardBody>
                                        <CardFooter className={classes.cardFooter}>
                                            <Button type={'submit'} simple color="rose" size="lg">
                                                Get started
                                            </Button>
                                        </CardFooter>
                                    </form>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Register;
