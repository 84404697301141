import React, {Component} from 'react'
import Typed from 'typed.js'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import styles from '../../material-ui/assets/jss/material-kit-react/views/components.js'
import {isMobile} from '../../utils/constant'
import './index.scss'


class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            strings: [
                'Hello 👋, Welcome to <strong>Sarkariexam.in</strong>',
                'नमस्कार, <strong>Sarkariexam.in</strong> पर आपका स्वागत है',
                `One stop to get latest news on all government jobs 🇮🇳 banking🏦 railways 🚃 results 🎓 and other information.`,
                'गवर्नमेंट जॉब्स, बैंक जॉब्स, परिणाम और अन्य सभी विषयों के बारे में जानकारी प्राप्त करने के लिए एक स्थान',
                '🤓 <strong>Subscribe today</strong> and get daily updates.',
                'आज ही सब्सक्राइब करें और दैनिक अपडेट प्राप्त करें।🤓',
                'Again 🙂',
                'Welcome to <strong>Sarkariexam.in</strong>',
            ],
        }
    }

    componentDidMount() {
        const {strings} = this.state
        const options = {
            strings: strings,
            typeSpeed: 50,
            backSpeed: 50,
        }
        this.typed = new Typed(this.el, options)
    }

    componentWillUnmount() {
        this.typed.destroy()
    }

    render() {
        return (
            <div className={"welcome"}>
                <div className={'welcome-note'}>
                    <div className={'heading1'}>
                        <div className="type-wrap">
                            <span
                                ref={el => {
                                    this.el = el
                                }}
                            />
                        </div>
                    </div>
                    <div className={'heading2'}>
                        One stop to latest news on all government jobs, results, admit card,
                        admission, related posts.

                    </div>
                    <div className={'heading3'}>
                        Please note, there are many website has similler name, make sure to type{' '}
                        <span><a href="http://sarkariexam.in/" target={'_blank'}>Sarkariexam.in</a></span> to visit this
                        website.
                    </div>
                </div>
            </div>
        )
    }
}

export default Welcome
