import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

export const AlertBox = (title, message, onYes, onNo) => {
    confirmAlert({
        title: title,
        message: message,

        buttons: [
            {
                label: 'Yes',
                onClick: () => onYes(),
            },
            {
                label: 'No',
                onClick: () => (onNo ? onNo() : ''),
            },
        ],
    })
}
