import { AuthContext } from '../context/auth'

export const isEmail = mail => {
    return true
}


export const keyRoute = (key) => {
    if(key === 0 ) {
        return 'latest-jobs'
    }
    if(key === 1 ) {
        return 'admit-cards'
    }
    if(key === 2 ) {
        return 'results'
    }
    if(key === 3 ) {
        return 'results'
    }
    if(key === 4 ) {
        return 'results'
    }
    if(key === 5 ) {
        return 'results'
    }
    if(key === 6 ) {
        return 'blog'
    }
    return ''
}


export const sortObject = (key, order = 'asc') => {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}
