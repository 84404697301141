import React, { useState, useEffect } from 'react'
import { Layout, Loader, PostList, Slider, Welcome } from '../../../components'
import GridContainer from '../../../material-ui/components/Grid/GridContainer'
import GridItem from '../../../material-ui/components/Grid/GridItem'
import NavPills from '../../../material-ui/components/NavPills/NavPills'
import { Dashboard, Schedule } from '@material-ui/icons'
import { useQuery } from 'react-apollo'
import { GET_ALL_POSTS_ALL_FIELD, GET_ALL_POSTS_SLIDER } from '../../../apis'

const Result = () => {
    const [result, setResult] = useState({
        data: [],
        loading: true,
        error: {},
    })
    const [page, setPage] = useState(1)
    const {
        loading: sliderLoading,
        error: sliderError,
        data: sliderData,
    } = useQuery(GET_ALL_POSTS_SLIDER, {
        variables: { showInSlider: true },
    })

    const {
        loading: resultLoading,
        error: resultError,
        data: resultData,
    } = useQuery(GET_ALL_POSTS_ALL_FIELD, {
        variables: {
            postSearchInput: { category: { name: 'Latest Results' } },
            page: page,
            size: 10,
        },
    })

    const loadMorePost = page => {
        const {
            loading: resultLoading,
            error: resultError,
            data: resultData,
        } = useQuery(GET_ALL_POSTS_ALL_FIELD, {
            variables: {
                postSearchInput: { category: { name: 'Latest Results' } },
                page: page,
                size: 10,
            },
        })

        setResult({
            ...result,
            data: [...data, resultData],
            error: resultError,
            loading: resultLoading,
        })
    }

    return (
        <Layout>
            <Welcome />
            {sliderLoading || resultLoading ? (
                <Loader />
            ) : (
                <Slider data={sliderData} loading={sliderLoading} />
            )}
            <div className={' post-panel'}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <NavPills
                            color="rose"
                            className={'nav-pills'}
                            horizontal={{
                                tabsGrid: { xs: 12, sm: 4, md: 4 },
                                contentGrid: { xs: 12, sm: 8, md: 8 },
                            }}
                            tabs={[
                                {
                                    tabButton: 'Result',
                                    tabIcon: Dashboard,
                                    tabContent: (
                                        <span>
                                            { resultData && resultData.getPosts && resultData.getPosts.length  > 0 &&
                                            resultData.getPosts.map(post => {
                                                    return (
                                                        <PostList
                                                            key={post.id}
                                                            post={post}
                                                        />
                                                    )
                                                })}
                                        </span>
                                    ),
                                },
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </Layout>
    )
}

export default Result
