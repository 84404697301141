import React, {useEffect, useState, useCallback, useContext} from 'react'
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Button from "../../material-ui/components/CustomButtons/Button.js";
import styles from "../../material-ui/assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";
import {useMutation} from 'react-apollo'
import {UPLOAD_FILE} from '../../apis/uploadFile'
import {useDropzone} from 'react-dropzone'
import Loader from '../Loader'
import BackupIcon from '@material-ui/icons/Backup';
import history from '../../history'
import {AuthContext} from '../../context/auth'


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";


const Upload = (props) => {
    const context = useContext(AuthContext);

    const [state, setState] = useState({
        isFileUploaded: false
    })
    const classes = useStyles();
    const [uploadFile, { uploadError, loading }] = useMutation(UPLOAD_FILE, {
        update(_, result){
            if(result && result.data) {
                props.onFileUpload(result.data.uploadFile, props.index)
                setState({...state, isFileUploaded: true})
                //props.closeModal()
            }
        },
        onError(err){
            if(err.graphQLErrors[0].extensions.code === "UNAUTHENTICATED"){
                context.logout()
            }
        }
    });
    const onDrop = useCallback(
        ([file], index) => {
            console.log("file", file)
            uploadFile({ variables: { file } });
        },
        [uploadFile]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

        return (
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    style={{
                        zIndex: "3"
                    }}
                    open={props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => props.closeModal()}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >

                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => props.closeModal()}
                        >
                            <Close className={classes.modalClose} />
                        </IconButton>
                        <h4 className={classes.modalTitle}>File upload</h4>
                    </DialogTitle>
                    <DialogContent
                        id="classic-modal-slide-description"
                        className={classes.modalBody}
                        style={{
                            background: "#fefefe",
                            border: "2px dashed #0079ff",
                            height: "75px",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            margin:"0px 12px"
                        }}
                    >
                        {loading &&  <Loader/>}
                        {state.isFileUploaded ? (
                            <div>File uploaded successfully, Please close this modal now.</div>
                        ) : (
                            <div {...getRootProps()} style={{outline: "none"}}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            height: "100%",
                                            alignItems : "center",
                                            outline: "none"
                                        }}
                                    ><BackupIcon />  Drop the files here ...</p>
                                ) : (
                                    <p
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems : "center",
                                            height: "100%",
                                            outline: "none"
                                        }}
                                    ><BackupIcon />  Drag 'n' drop some files here, or click to select files</p>
                                )}
                            </div>
                        ) }


                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                        <Button
                        onClick={() => props.closeModal()}
                        color="danger"
                        simple
                        disabled={!state.isFileUploaded}
                    >
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
}

export default Upload
