import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormHelperText, MenuItem, InputLabel} from '@material-ui/core'
import SelectInput from '@material-ui/core/Select'

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 200,
        width: '100%',
        margin: '10px 0',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputClass : {
        color: "#495057",
        fontSize: "14px",
        height: "unset",
        fontWeight: "400",
        lineHeight: "1.42857",
        opacity: "1",
        "&::placeholder": {
            color: "#AAAAAA"
        }
    },
    inputLabel : {
        fontSize: "14px"
    }
}))

const Select = ({ label='', fieldFor='', value='', id='', listArray=[], onChange=()=>{}, isError=false, errorText='', customClass='', disabled=false, placeholder='', changeArgs={}, multiple=false }) => {
    const classes = useStyles()
    const inputLabel = useRef(null)
    const [labelWidth, setLabelWidth] = useState(0)
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth)
    }, [])

    const handleSelectChange = (e) => {
        if(changeArgs && changeArgs.length) {
            onChange(fieldFor, e.target.value, ...changeArgs)
        } else {
            onChange(fieldFor, e.target.value)
        }

    }
    return (
            <FormControl className={classes.formControl} error={isError} disabled={disabled}>
                <InputLabel className={classes.inputLabel} ref={inputLabel} id="simple-select-label">{label}</InputLabel>
                <SelectInput
                    multiple={multiple}
                    labelId="simple-select-label"
                    id={id}
                    value={value}
                    name={fieldFor}
                    onChange={(e)=> handleSelectChange(e)}
                    labelWidth={labelWidth}
                    className={classes.inputClass + " " + customClass}

                >
                    {listArray && listArray.length &&
                        listArray.map((item, index) => (
                            <MenuItem className={classes.inputClass} key={index} value={item.value}>{item.text}</MenuItem>
                        ))}
                </SelectInput>
                {isError && <FormHelperText>{errorText}</FormHelperText>}
                {placeholder && <FormHelperText>{placeholder}</FormHelperText>}
            </FormControl>
    )
}

export default Select;
