import React from 'react'
import { Link } from 'react-router-dom'
import { keyRoute} from '../../utils/functions'
import './index.scss'
import { TagView } from '../TagView/TagView'
import { Icon } from 'antd'
import { Like } from '../../utils/svgs'

export const ListView = props => {
    const { title, data, classes } = props
    const now = new Date(Date.now()).toLocaleString().split(',')[0]
    return (
        <div className="table-container">
            <div className={'table-header ' + classes}>{title}</div>
            <div className="table-body">
                {data && data.getPosts && data.getPosts.length > 0 ? (
                    data.getPosts.map((post, index) => {
                        const { id, postHeading, category } = post
                        // console.log("category", post)
                        return (
                        <div className="post" key={index}>
                            {post.showBadge && <div className="badge">New</div>}
                            <div className="post-title">
                                <Link to={'/posts/' + keyRoute(category[0].key)+'/' + postHeading.split(" ").join("-")+"_"+id}>{postHeading}</Link>
                            </div>
                            {/*<div className="tags-container">*/}
                            {/*    <div className="post-no-of-opening">*/}
                            {/*        <TagView*/}
                            {/*            classes={'post-tag'}*/}
                            {/*            type={'circle'}*/}
                            {/*            text={'Vacancy'}*/}
                            {/*            value={post.vacancy || 'Coming soon'}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <TagView*/}
                            {/*            classes={'post-tag'}*/}
                            {/*            type={'circle'}*/}
                            {/*            text={'Views'}*/}
                            {/*            value={post.views}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*    <div className="post-date">*/}
                            {/*        <small>*/}
                            {/*            <TagView*/}
                            {/*                classes={'post-tag'}*/}
                            {/*                type={'circle'}*/}
                            {/*                text={'Posted'}*/}
                            {/*                value={*/}
                            {/*                    // post.date.postCreatedAt ||*/}
                            {/*                    post.date.postCreatedAt &&*/}
                            {/*                    post.date.postCreatedAt === now*/}
                            {/*                        ? 'Today'*/}
                            {/*                        : post.date.postCreatedAt ||*/}
                            {/*                          'Coming soon'*/}
                            {/*                }*/}
                            {/*            />*/}
                            {/*        </small>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="post-controls">*/}
                            {/*    <div>*/}
                            {/*        <Like /> Like*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <Icon type="message" />*/}
                            {/*        Comment*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <Icon type="share" />*/}
                            {/*        Share*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    )})
                ) : (
                    <div> No Data available</div>
                )}
            </div>
        </div>
    )
}
