import React from 'react'
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import history from '../../history'
import { colorPallet } from '../../utils/constant'
import './index.scss'
import { Loader } from '../index'
import { Link } from 'react-router-dom'
import {keyRoute} from '../../utils/functions'

const BillBoard = props => {
    const { loading } = props
    const { sliderHeading, likeCount, views, category } = props.data

    let loc
    if (typeof history !== 'undefined') {
        loc = history.location.pathname
    }

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div
                    className={'billBoard'}
                    style={{ background: colorPallet[props.index] }}
                >
                    <div className={'heading'}>
                        <Link to={'/posts/' + keyRoute(category[0] && category[0].key)+'/' + props.data.postHeading.split(" ").join("-")+"_"+props.data.id} >
                            {sliderHeading}
                        </Link>
                    </div>
                    <div className={'action'} style={{ background: `darken( ${colorPallet[props.index]}, 10%)`}}>

                        <div className={'commnetCount'}>
                            Views : {views}
                        </div>
                        <div className={'likeCount'}>
                            Liked {likeCount}
                        </div>
                        <div className={'trending'}>
                            Trending <TrendingUpIcon />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BillBoard
