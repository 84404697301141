import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import {AuthContext} from '../../context/auth'
import Navbar from '../Navbar'
import Footer from '../../material-ui/components/Footer/Footer'
import styles from '../../material-ui/assets/jss/material-kit-react/views/components.js'
import './index.scss'

const useStyles = makeStyles(styles)

export const Layout = ({children}) => {
    const {user} = useContext(AuthContext)
    const classes = useStyles()
    return (
        <div>
            <Navbar/>
            <div className={'main-panel'}>
                <div className={classNames(classes.main, classes.mainRaised)}>{children}</div>
                <Footer/>
            </div>
        </div>
    )
}


