export const isMobile = typeof window !== 'undefined' && window.innerWidth <= 767;
export const isUptoTabletPortrait = typeof window !== 'undefined' && window.innerWidth <= 768;
export const IsIpadPortrait = typeof window !== 'undefined' && window.innerWidth === 768;
export const isMobileTablet = typeof window !== 'undefined' && window.innerWidth <= 1024;
// export const isTablet = 768 <= typeof window !== 'undefined' && window.innerWidth && window.innerWidth <= 1024
export const isIphoneX = typeof window !== 'undefined' && window.innerWidth >= 769 && typeof window !== 'undefined' && window.innerWidth <= 812;

export const isWindow = typeof window !== 'undefined';
export const SITE_TXT = {};

export const CategoryFormColumn = [
  {
    title: 'Category Name',
    dataIndex: 'categoryTitle',
    key: 'categoryTitle',
  },
  {
    title: 'Visible',
    dataIndex: 'visible',
    key: 'visible',
  },
  {
    title: 'Order',
    dataIndex: 'order',
    key: 'order',
  },
];

export const webSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 5000,
  swipeToSlide: true,
  autoplaySpeed: 0,
  pauseOnHover: true,
  cssEase: 'linear',
  easing: 'linear',
  swipe: true,
  touchMove: true,
};

export const mobileSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  speed: 5000,
  swipeToSlide: true,
  autoplaySpeed: 0,
  pauseOnHover: true,
  cssEase: 'linear',
  easing: 'linear',
  swipe: true,
  touchMove: true,
};

export const colorPallet = [
  '#ff1744',
  '#651fff',
  '#0097a7',
  '#388e3c',
  '#546e7a',
  '#7b1fa2',
  '#ff4081',
  '#00796b',
  '#4a148c',
  '#d500f9',
  '#757575',
  '#ad1457',
  '#0288d1',
  '#1b5e20',
  '#ff3d00',
  '#304ffe',
  '#00bfa5',
  '#7b1fa2',
  '#ff4081',
  '#00796b',
  '#4a148c',
  '#d500f9',
  '#757575',
  '#ad1457',
  '#0288d1',
  '#1b5e20',
  '#ff3d00',
  '#304ffe',
  '#00bfa5',
];

export const showAds = true;
