 import React, { useState, useContext } from 'react';
import { Loader } from '../../components';
import { useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import FormHelperText from '@material-ui/core/FormHelperText';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
// core components
import GridContainer from '../../material-ui/components/Grid/GridContainer.js';
import GridItem from '../../material-ui/components/Grid/GridItem.js';
import Button from '../../material-ui/components/CustomButtons/Button.js';
import Card from '../../material-ui/components/Card/Card.js';
import CardBody from '../../material-ui/components/Card/CardBody.js';
import CardHeader from '../../material-ui/components/Card/CardHeader.js';
import CardFooter from '../../material-ui/components/Card/CardFooter.js';
import CustomInput from '../../material-ui/components/CustomInput/CustomInput.js';
import { useForm } from '../../utils/customHooks';
import styles from '../../material-ui/assets/jss/material-kit-react/views/loginPage.js';
import { LOGIN_USER } from '../../apis/users';
import { AuthContext } from '../../context/auth';
import {Layout} from "../../components"
import image from '../../assets/sign.jpg'
import {useToasts} from 'react-toast-notifications'

const useStyles = makeStyles(styles);
const Login = (props) => {
    const { addToast } = useToasts()
    const context = useContext(AuthContext);
    const [errors, setErrors] = useState({
        username: '',
        password: ''
    });

    const { onChange, onSubmit, values } = useForm(login, {
        email: '',
        password: '',
    });
    const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
    setTimeout(function() {
        setCardAnimation('');
    }, 700);

    const [loginUser, { loading }] = useMutation(LOGIN_USER, {
        update(_, { data: { login: userData } }) {
            // console.log("userData", userData)
            context.login(userData);
            if (userData.usertype !== 'admin') {
                props.history.push("/")
            } else {
                // console.log("userData 2", userData)
                props.history.push("/app/admin")
            }
        },
        onError(err) {
            console.log('err', err.graphQLErrors[0].message);
            setErrors({...errors, username: err.graphQLErrors[0].message});
        },
        variables: {
            username: values.email,
            password: values.password,
        },
    });

    function login() {
        loginUser();
    }

    const classes = useStyles();
    return (
        <div className={'login-page'}>
            <Layout>
                {loading && <Loader />}
                <div
                    className={classes.pageHeader}
                    style={{
                        backgroundImage: 'url(' + image + ')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                    }}
                >
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={10} sm={12} md={4}>
                                <Card className={classes[cardAnimaton]}>
                                    <form className={classes.form} onSubmit={onSubmit}>
                                        <CardHeader color="rose" className={classes.cardHeader}>
                                            <h4>Login</h4>
                                            <div className={classes.socialLine}>
                                                <Button
                                                    justIcon
                                                    href="#pablo"
                                                    target="_blank"
                                                    color="transparent"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className={'fab fa-twitter'} />
                                                </Button>
                                                <Button
                                                    justIcon
                                                    href="#pablo"
                                                    target="_blank"
                                                    color="transparent"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className={'fab fa-facebook'} />
                                                </Button>
                                                <Button
                                                    justIcon
                                                    href="#pablo"
                                                    target="_blank"
                                                    color="transparent"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className={'fab fa-google-plus-g'} />
                                                </Button>
                                            </div>
                                        </CardHeader>
                                        <p className={classes.divider}>Or Be Classical</p>
                                        <CardBody>
                                            <CustomInput
                                                labelText="Email..."
                                                id="email"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Email
                                                                className={classes.inputIconsColor}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    value: values.username,
                                                    error: !!errors.username,
                                                    onChange: e => onChange('email', e.target.value),
                                                }}
                                            />
                                            {!!errors.username && (
                                                <FormHelperText error={!!errors.username}>
                                                    {' '}
                                                    {errors.username}{' '}
                                                </FormHelperText>
                                            )}
                                            <CustomInput
                                                labelText="Password"
                                                id="password"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'password',
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LockIcon />
                                                        </InputAdornment>
                                                    ),
                                                    autoComplete: 'off',
                                                    value: values.password,
                                                    error: !!errors.password,
                                                    onChange: e => onChange('password', e.target.value),
                                                }}
                                            />
                                            {!!errors.password && (
                                                <FormHelperText error={!!errors.password}>
                                                    {' '}
                                                    {errors.password}{' '}
                                                </FormHelperText>
                                            )}
                                        </CardBody>
                                        <CardFooter className={classes.cardFooter}>
                                            <Button type={'submit'} simple color="rose" size="lg">
                                                Login
                                            </Button>
                                        </CardFooter>
                                    </form>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Login;
