import React, { Component } from 'react'
import {
    FacebookShareButton as Facebook,
    EmailShareButton as Email,
    LinkedinShareButton as LinkedIn,
    TelegramShareButton as Telegram,
    TwitterShareButton as Twitter,
    WhatsappShareButton as WhatsApp,
    FacebookIcon as FBIcon,
    EmailIcon,
    LinkedinIcon as LIIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from 'react-share'
import {keyRoute} from '../../../utils/functions'
const Share = props => {
    console.log("share", props, keyRoute(props.post.category[0].key))
    const name = props.post.postHeading
    const url = encodeURI(
        'www.sarkariexam.in/posts/'+keyRoute(props.post.category[0].key)+'/' +
            props.post.postHeading.split(' ').join('-') +
            '_' +
            props.post.id
    )
    const combine = name + '  \n ' + url
    return (
        <div className="share-panel">
            <Facebook url={url} quote={name} separator={' || \n'}>
                <FBIcon size={32} round={true} />
            </Facebook>
            <Email body={combine} subject={`${name}`} separator={" || "}>
                <EmailIcon size={32} round={true} />
            </Email>
            <LinkedIn url={`${url}`} separator={' || \n'}>
                <LIIcon size={32} round={true} />
            </LinkedIn>
            <Telegram url={url} title={name} separator={' || \n'}>
                <TelegramIcon size={32} round={true} />
            </Telegram>
            <Twitter link={url} label={combine}>
                <TwitterIcon size={32} round={true} />
            </Twitter>
            <WhatsApp url={url} title={name} separator={' || \n'}>
                <WhatsappIcon size={32} round={true} />
            </WhatsApp>
        </div>
    )
}

export default Share
