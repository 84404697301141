import React, {useEffect, useState} from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { webSettings, mobileSettings, isMobile} from '../../utils/constant'
import BillBoard from '../BillBoard'
import "./index.scss"


export const  ReactSlider = (props) => {
    const {data, loading} = props

    const settings = isMobile? mobileSettings : webSettings


    return (
        <div className={'sectionsSlider'}>
            <Slider {...settings}>
                {
                    data && data.getPosts && data.getPosts.length && data.getPosts.map((item, index) =>
                        <BillBoard key={index} data={item} index={index}/>
                    )
                }
            </Slider>
        </div>
    )

}
