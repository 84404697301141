import {container} from "../../../material-kit-react.js"

const tabsStyle = {
    section: {
        background: "#F3F3FB",
        padding: "10px 0",

    },
    container,
    textCenter: {
        textAlign: "center",
        margin: "auto"
    },

}

export default tabsStyle
