import React, { useEffect, useState, } from 'react'
import CustomInput from '../../../../material-ui/components/CustomInput/CustomInput'
import GridItem from '../../../../material-ui/components/Grid/GridItem'
import GridContainer from '../../../../material-ui/components/Grid/GridContainer'
import {Select, Upload} from '../../../../components'
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import {makeStyles} from '@material-ui/core/styles'
import CustomButton from '../../../../components/Button'



const useStyles = makeStyles(theme => ({
    inputLabel : {
        fontSize: "14px",
        color: "#fff"
    },
    btnContainer : {
        display: "flex",
        justifyContent: "left",
        alignContent: "center",
        flexDirection: "rows"
    },
    btnDone: {
        marginLeft: "10px",

    }
}))

export const Attachment = (props) => {
    const classes = useStyles()

    const [state, setState] = useState({
        row: {
            jobSourceName: '',
            linkLabel: 'Click here',
            linkUrl: "",
            urlType: '',
            fileDetail: {
                filePath: '',
                fileName:'',
                createdAt: '',
                folder: ''
            },
            isFileOrUrl: '',
        },
        rows: [],
        urlTypeArray: [
            {
                value: 1,
                text: 'Link',
            },
            {
                value: 2,
                text: 'Attachment',
            }
        ],
        uploadModal: false,
        currentIndex: ""

    })

    const updateRowsData = (field, index, value) => {
        const rows = [...state.rows];
        rows[index] = {
            ...state.rows[index],
            [field]: value,

        };
        setState({...state, rows: rows});
        if(field === 'urlType' && value > 0) {
            rows[index] = {
                ...state.rows[index],
                [field]: value,
                isFileOrUrl: value
            };
            setState({...state, rows: rows});
        }
    }
    const addRows = (() => {
        setState({...state, rows: [...state.rows, state.row]})
    })

    useEffect(()=> {
        setState({...state, rows: [...state.rows, state.row]})
    },[])

    const handleChange = (fieldFor, value, index) => {
        //updateRowsData(fieldFor, value, index)
        const rows = [...state.rows];
        rows[index] = {
            ...state.rows[index],
            [fieldFor]: value,

        };
        setState({...state, rows: rows});
        if(fieldFor === 'urlType' && value > 0) {
            rows[index] = {
                ...state.rows[index],
                [fieldFor]: value,
                isFileOrUrl: value
            };
            setState({...state, rows: rows});
        }
    }

    const deleteRow = (index) => {
        // console.log("delete row")
        const rows = [...state.rows]
        rows.splice(index, 1)
        setState({...state, rows: rows  })
        props.onDone(state.rows)
    }

    const template = (item, index) => {
        return (
            <GridContainer key={index}>
                <GridItem xs={10} sm={4} md={2} lg={3} className={'row padding'}>
                    <Select
                        id={'urlType'}
                        fieldFor={'urlType'}
                        value={state.rows[index]['urlType']}
                        onChange={handleChange}
                        listArray={state.urlTypeArray}
                        label={'Url Type'}
                        customClass={'category-select'}
                        changeArgs={[index]}
                    />
                </GridItem>
                {state.rows[index]['isFileOrUrl'] === 1 ? (
                    <GridItem xs={10} sm={4} md={2} lg={3} className={'row padding'}>
                        <CustomInput
                            labelText="Link Url"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type: 'text',
                                autoComplete: 'off',
                                value: state.rows[index]['linkUrl'],
                                // error: errors && !!errors.Heading,
                                onChange: e => handleChange('linkUrl', e.target.value, index),
                            }}
                        />
                    </GridItem>
                ) : (state.rows[index]['isFileOrUrl'] === 2) &&  (
                    <GridItem xs={10} sm={4} md={2} lg={3} className={'row padding'}>
                        {state.rows[index]['fileDetail']['fileName'] === "" ? (
                                <span className={'uploadClass'} onClick={() => handleUploadClick(index)}>Click here to upload the file</span>
                            )
                            : (
                                <CustomInput
                                    labelText="Attachment"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}

                                    inputProps={{
                                        type: 'text',
                                        autoComplete: 'off',
                                        readOnly: true,
                                        value: state.rows[index]['fileDetail']['fileName'],
                                        //value: 'test',
                                        // error: errors && !!errors.Heading,
                                        onChange: e => handleChange('filePath', e.target.value, index),
                                    }}
                                />
                            )}

                    </GridItem>
                )}
                {state.rows[index]['isFileOrUrl'] !=='' && (
                    <React.Fragment>
                        <GridItem xs={10} sm={4} md={2} lg={3} className={'row padding'}>
                            <CustomInput
                                labelText="Url Source Name"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    type: 'text',
                                    autoComplete: 'off',
                                    value: state.rows[index]['jobSourceName'],
                                    // error: errors && !!errors.Heading,
                                    onChange: e => handleChange('jobSourceName', e.target.value, index),
                                }}
                            />
                        </GridItem>
                        <GridItem xs={10} sm={4} md={2} lg={2} className={'row padding'}>
                            <CustomInput
                                labelText="Url Label"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    type: 'text',
                                    autoComplete: 'off',
                                    value: state.rows[index]['linkLabel'],
                                    // error: errors && !!errors.Heading,
                                    onChange: e => handleChange('linkLabel', e.target.value, index),
                                }}
                            />
                        </GridItem>
                    </React.Fragment>
                )}
                <GridItem xs={10} sm={4} md={2} lg={1} className={'row padding deleteIcon'}>
                    <span onClick={()=> deleteRow(index)}> <DeleteIcon color="error" /></span>
                    {
                       props.isDone && (
                            <CheckIcon  style={{color: "#4caf50"}} />
                        )
                    }
                </GridItem>
            </GridContainer>
        )}
    const rows = () => {
        return state.rows.map((item, index) => {
            return template(item, index)
        })
    }
    const callSubmit = () => {
        console.log("Send the data to PostForm.js")
        props.onDone(state.rows)
    }
    const handleUploadClick = (index) => {
        console.log("index", index)
        setState({...state, currentIndex: index, uploadModal: true})
    }
    const closeUploadModal = () => {
        setState({...state, uploadModal: false})
    }
    const handleFileUpload = (uploadProps, index) => {
        const data = {
            filePath: uploadProps.filePath,
            fileName: uploadProps.fileName,
            createdAt: uploadProps.createdAt,
            folder: uploadProps.folder
        }
        updateRowsData('fileDetail', index, data)
    }
    return (
        <div>
            <div>{rows()}</div>

            <div className={classes.btnContainer}>
                <CustomButton
                    className={classes.inputLabel}
                    color={'info'}
                    children={'Add a new Link/File'}
                    onClick={addRows}

                />
                <CustomButton
                    className={classes.inputLabel}
                    color={'success'}
                    children={'Done'}
                    onClick={callSubmit}
                />
            </div>
            {state.uploadModal && (<Upload
                open={state.uploadModal}
                onFileUpload={handleFileUpload}
                index={state.currentIndex}
                closeModal={closeUploadModal}

            />)
            }
        </div>
    )
}
