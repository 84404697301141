import React, { useEffect } from 'react';
import { Query, useQuery } from 'react-apollo';
import MapIcon from '@material-ui/icons/Map';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import GridContainer from '../../material-ui/components/Grid/GridContainer.js';
import GridItem from '../../material-ui/components/Grid/GridItem.js';
import styles from '../../material-ui/assets/jss/material-kit-react/views/componentsSections/tabsStyle.js';
import {
  GET_ALL_POSTS_NEWS_BOARD,
  GET_ALL_POSTS_SLIDER,
  GET_ALL_POSTS_HOME,
} from '../../apis/posts';
import './index.scss';
import '../../styles/main.scss';
import {
  Layout, Loader, Tabs, Slider, Welcome,
} from '../../components';

import { showAds } from '../../utils/constant';
import { GET_CATEGORY } from '../../apis/category';
import { ListView } from '../../components/ListView/ListView';
import { sortObject } from '../../utils/functions';

const useStyles = makeStyles(styles);

const Home = () => {
  const classes = useStyles();

  const { loading: newBoardLoading, error: n, data: newBoardData } = useQuery(
    GET_ALL_POSTS_NEWS_BOARD,
    {
      variables: { showInNewsBoard: true },
    },
  );

  const {
    loading: sliderLoading,
    error: sliderError,
    data: sliderData,
  } = useQuery(GET_ALL_POSTS_SLIDER, {
    variables: { showInSlider: true },
  });

  const {
    loading: catLoading,
    error: catError,
    data: categoryData,
  } = useQuery(GET_CATEGORY, {
    variables: { visible: true },
  });

  const { loading: cat1, error: cat1Error, data: cat1Data } = useQuery(
    GET_ALL_POSTS_HOME,
    {
      variables: { order: 1 },
    },
  );

  const { loading: cat2, error: cat2Error, data: cat2Data } = useQuery(
    GET_ALL_POSTS_HOME,
    {
      variables: { order: 2 },
    },
  );

  const { loading: cat3, error: cat3Error, data: cat3Data } = useQuery(
    GET_ALL_POSTS_HOME,
    {
      variables: { order: 3 },
    },
  );

  const { loading: cat4, error: cat4Error, data: cat4Data } = useQuery(
    GET_ALL_POSTS_HOME,
    {
      variables: { order: 4 },
    },
  );

  const { loading: cat5, error: cat5Error, data: cat5Data } = useQuery(
    GET_ALL_POSTS_HOME,
    {
      variables: { order: 5 },
    },
  );

  const { loading: cat6, error: cat6Error, data: cat6Data } = useQuery(
    GET_ALL_POSTS_HOME,
    {
      variables: { order: 6 },
    },
  );

  const routePath = (category) => {
    const cur_path = '/posts/';
    switch (category.categoryTitle) {
      case 'Latest Sarkari Jobs':
        return cur_path.concat('latest-jobs');
      case 'Latest Results':
        return cur_path.concat('result');
      case 'Latest Banking Jobs':
        return cur_path.concat('banking-jobs');
      case 'Latest Teaching Jobs':
        return cur_path.concat('teaching-jobs');
      case 'Answer Key':
        return cur_path.concat('answer-key');
      case 'Latest Admit Cards':
        return cur_path.concat('admit-cards');
      default:
        return '/';
    }
  };

  const { loading, error, data: stateCategory } = useQuery(GET_CATEGORY);

  const optionRegion = stateCategory
        && stateCategory.getCategories
          .filter(
            (item) => item.categoryType && item.categoryType.typeCode === 3,
          )
          .map((item, index) => ({
            key: index,
            name: item.categoryTitle,
            visible: item.visible,
            order: item.order,
          }));

  const regionList = optionRegion
        && optionRegion.length > 0
        && optionRegion.sort(sortObject('name'));

  return (
    <Layout>
      <Welcome />
      {sliderLoading
            || cat1
            || cat2
            || cat3
            || cat4
            || cat5
            || cat6
            || catLoading
            || newBoardLoading ? (
              <Loader />
        ) : (
          <Slider data={sliderData} loading={sliderLoading} />
        )}

      <div className="home">
        {showAds && (
        <GridContainer className="ad-space">
          <GridItem xs={12} sm={12} md={12}>
            <ins
              className="adsbygoogle"
              data-ad-client="ca-pub-1422138996530336"
              data-ad-slot="3317584861"
              data-ad-format="auto"
              data-full-width-responsive="true"
            />
          </GridItem>
        </GridContainer>
        )}
        <div className={classes.section}>
          <div className={`${classes.container} notification-panel`}>
            {newBoardLoading ? (
              <h2>Loading ...</h2>
            ) : (
              <Tabs
                data={newBoardData}
                loading={newBoardLoading}
              />
            )}
          </div>
        </div>
        {showAds && (
        <GridContainer className="ad-space">
          <GridItem xs={12} sm={12} md={12}>
            <ins
              className="adsbygoogle"
              data-ad-client="ca-pub-1422138996530336"
     data-ad-slot="3429443118"
              data-ad-format="auto"
              data-full-width-responsive="true"
            />
          </GridItem>
        </GridContainer>
        )}
        <div className="post-panel">
          {categoryData && categoryData.getCategories.length > 0 && (
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <div className="cat1-container">
                <ListView
                  classes="cat1"
                  title={
                                            categoryData
                                            && categoryData.getCategories.length
                                                > 0
                                            && categoryData.getCategories[0]
                                              .categoryTitle
                                        }
                  data={cat1Data}
                />
                <div className="more">
                  <NavLink
                    to={routePath(
                      categoryData.getCategories[0],
                    )}
                    color="transparent"
                    className={classes.navLink}
                  >
                    More
                    {categoryData
                                                && categoryData.getCategories
                                                  .length > 0
                                                && categoryData.getCategories[0]
                                                  .categoryTitle}
                  </NavLink>
                </div>
                </div>
              </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <div className="cat2-container">
                <ListView
                  classes="cat2"
                  title={
                                            categoryData
                                            && categoryData.getCategories.length
                                                > 1
                                            && categoryData.getCategories[1]
                                              .categoryTitle
                                        }
                  data={cat2Data}
                />
                <div className="more">
                  <NavLink
                    to={routePath(
                      categoryData.getCategories[1],
                    )}
                    color="transparent"
                    className={classes.navLink}
                  >
                    More
                    {' '}
                    {categoryData.getCategories.length
                                                > 1
                                                && categoryData.getCategories[1]
                                                  .categoryTitle}
                  </NavLink>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <div className="cat3-container">
                <ListView
                  classes="cat3"
                  title={
                                            categoryData
                                            && categoryData.getCategories.length
                                                > 2
                                            && categoryData.getCategories[2]
                                              .categoryTitle
                                        }
                  data={cat3Data}
                />
                <div className="more">
                  <NavLink
                    to={routePath(
                      categoryData.getCategories[2],
                    )}
                    color="transparent"
                    className={classes.navLink}
                  >
                    More
                    {' '}
                    {categoryData
                                                && categoryData.getCategories
                                                  .length > 2
                                                && categoryData.getCategories[2]
                                                  .categoryTitle}
                  </NavLink>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          )
          }

          {showAds && (
          <GridContainer className="ad-space">
            <GridItem xs={12} sm={12} md={12}>
              <ins
                className="adsbygoogle"
                data-ad-client="ca-pub-1422138996530336"
     data-ad-slot="7037322115"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />
            </GridItem>
          </GridContainer>
          )}

          <GridContainer className="job-by-state">
            <GridItem xs={12} sm={12} md={12}>
              <div className="heading">🇮🇳 Jobs by State 🇮🇳</div>
              <div className="container">
                {regionList
                                    && regionList.map((item, index) => (
                                      <div key={index} className="region">
                                        <MapIcon
                                          style={{ fontSize: 18 }}
                                        />
                                        {item.name}
                                        <MoreHorizIcon />
                                      </div>
                                    ))}
              </div>
            </GridItem>
          </GridContainer>

          {categoryData && categoryData.getCategories.length > 0 && (
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <div className="cat4-container">
                <ListView
                  classes="cat4"
                  title={
                                            categoryData
                                            && categoryData.getCategories.length
                                                > 3
                                            && categoryData.getCategories[3]
                                              .categoryTitle
                                        }
                  data={cat4Data}
                />
                <div className="more">
                  <NavLink
                    to={routePath(
                      categoryData.getCategories[3],
                    )}
                    color="transparent"
                    className={classes.navLink}
                  >
                    More
                    {' '}
                    {categoryData
                                                && categoryData.getCategories
                                                  .length > 3
                                                && categoryData.getCategories[3]
                                                  .categoryTitle}
                  </NavLink>
                </div>
              </div>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={4}
            >
              <div className="cat5-container">
                <ListView
                  classes="cat5"
                  title={
                                            categoryData
                                            && categoryData.getCategories.length
                                                > 4
                                            && categoryData.getCategories[4]
                                              .categoryTitle
                                        }
                  data={cat5Data}
                />
                <div className="more">
                  <NavLink
                    to={routePath(
                      categoryData.getCategories[4],
                    )}
                    color="transparent"
                    className={classes.navLink}
                  >
                    More
                    {' '}
                    {categoryData
                                                && categoryData.getCategories
                                                  .length > 4
                                                && categoryData.getCategories[4]
                                                  .categoryTitle}
                  </NavLink>
                </div>
              </div>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              lg={4}
            >
              <div className="cat6-container">
                <ListView
                  classes="cat6"
                  title={
                                            categoryData
                                            && categoryData.getCategories.length
                                                > 5
                                            && categoryData.getCategories[5]
                                              .categoryTitle
                                        }
                  data={cat6Data}
                />
                <div className="more">
                  <NavLink
                    to={routePath(
                      categoryData.getCategories[5],
                    )}
                    color="transparent"
                    className={classes.navLink}
                  >
                    More
                    {' '}
                    {categoryData
                                                && categoryData.getCategories
                                                  .length > 5
                                                && categoryData.getCategories[5]
                                                  .categoryTitle}
                  </NavLink>
                </div>
              </div>
            </GridItem>
            <GridItem
              xs={12}
            >
              <div className="blog-container">
                {/* This will hold the blog post for my website */}
              </div>
            </GridItem>
          </GridContainer>
          )}
        </div>
        {showAds && (
        <GridContainer className="ad-space">
          <GridItem xs={12} sm={12} md={12}>
            <ins
              className="adsbygoogle"
              data-ad-client="ca-pub-1422138996530336"
     data-ad-slot="7173247000"
              data-ad-format="auto"
              data-full-width-responsive="true"
            />
          </GridItem>
        </GridContainer>
        )}
      </div>
    </Layout>
  );
};

export default Home;
