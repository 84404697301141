import React, {useReducer, createContext} from 'react'
import jwtDecode from 'jwt-decode'
import history from "../history"
const ROUTE_ROOT = '/'

const initialState = {
    user: null
}

if (typeof window !== 'undefined') {
    if (localStorage.getItem('jwtToken')) {
        const decodedToken = jwtDecode(localStorage.getItem('jwtToken'))

        if (decodedToken.exp * 1000 < Date.now()) {
            localStorage.removeItem('jwtToken')
            history.replace(ROUTE_ROOT)
        } else {
            initialState.user = decodedToken
        }
    }
}

const AuthContext = createContext({
    user: null,
    login: (userData) => {
    },
    logout: () => {
    }
})

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            console.log("action", action)
            return {
                ...state,
                user: action.payload
            }
        case 'LOGOUT':
            return {
                ...state,
                user: null
            }
        default:
            return state
    }
}

const AuthProvider = (props) => {
    const [state, dispatch] = useReducer(authReducer, initialState)
    const login = (userData) => {
        if (typeof window !== 'undefined') {
            localStorage.setItem('jwtToken', userData.token)
            dispatch({
                type: 'LOGIN',
                payload: userData
            })
        }
    }

    const logout = () => {
        if (typeof window !== 'undefined') {
            localStorage.removeItem('jwtToken')
            dispatch({type: 'LOGOUT'})
        }
    }

    return (
        <AuthContext.Provider
            value={{user: state.user, login, logout}}
            {...props}
        />
    )
}

export {AuthContext, AuthProvider}
