import * as React from 'react'
import {Layout} from "../../../components"

export default (props) => {
    return (
        <Layout>
            This will hold the User listing page
        </Layout>
    )
}
