import React, { useState, useEffect, useRef, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { Editor } from '@tinymce/tinymce-react'
import Divider from '@material-ui/core/Divider'
import Datetime from 'react-datetime'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import GridContainer from '../../../../material-ui/components/Grid/GridContainer.js'
import GridItem from '../../../../material-ui/components/Grid/GridItem.js'
import CustomInput from '../../../../material-ui/components/CustomInput/CustomInput.js'
import styles from '../../../../material-ui/assets/jss/material-kit-react/views/componentsSections/basicsStyle.js'
import CustomButton from '../../../../components/Button'
import { GET_CATEGORY } from '../../../../apis/category'
import { useForm } from '../../../../utils/customHooks'
import { FormControlLabel, Switch } from '@material-ui/core'
import { Attachment } from './Attachment'
import { Loader, ModifiedSelect } from '../../../../components'
import moment from 'moment'
import { CREATE_POST, GET_ALL_POSTS_ALL_FIELD } from '../../../../apis/posts'
import { AuthContext } from '../../../../context/auth'
import { useToasts } from 'react-toast-notifications'

const useStyles = makeStyles(styles)

export const PostForm = props => {
    const { addToast } = useToasts()
    const context = useContext(AuthContext)
    const classes = useStyles()
    const [content, setContent] = useState('')
    const [isDone, setDone] = useState(false)

    const initialState = {
        showBadge: true,
        showInSlider: true,
        sliderHeading: '',
        showInNewsBoard: false,
        newsBoardHeading: '',
        showPost: true,
        postHeading: '',
        postDescription: '',
        vacancy: '',
        education: [],
        region: [],
        category: [],
        tags: [],
        postCreatedAt: format(new Date(), 'dd/MM/yyyy'),
        applicationStartDate: '',
        lastDateForApplication: '',
        feePayLastDate: '',
        admitCardDate: '',
        examDate: '',
        resultDate: '',
        attachmentData: [],
        body: '',
    }
    const { onChange, onSubmit, onSelect, resetForm, values } = useForm(
        createNewPost,
        initialState
    )
    /***
     * Gqqphql queries here
     **/
    const { loading, error, data } = useQuery(GET_CATEGORY)
    const optionCategory =
        data &&
        data.getCategories
            .filter(
                item => item.categoryType && item.categoryType.typeCode === 1
            )
            .map((item, index) => ({
                key: index,
                name: item.categoryTitle,
                visible: item.visible,
                order: item.order,
            }))
    const optionEducation =
        data &&
        data.getCategories
            .filter(
                item => item.categoryType && item.categoryType.typeCode === 2
            )
            .map((item, index) => ({
                key: index,
                name: item.categoryTitle,
                visible: item.visible,
                order: item.order,
            }))

    const optionRegion =
        data &&
        data.getCategories
            .filter(
                item => item.categoryType && item.categoryType.typeCode === 3
            )
            .map((item, index) => ({
                key: index,
                name: item.categoryTitle,
                visible: item.visible,
                order: item.order,
            }))

    const optionTag =
        data &&
        data.getCategories
            .filter(
                item => item.categoryType && item.categoryType.typeCode === 4
            )
            .map((item, index) => ({
                key: index,
                name: item.categoryTitle,
            }))
    const [
        createPost,
        { loading: createPostLoading, error: createPostError },
    ] = useMutation(CREATE_POST, {
        variables: {
            showBadge: values.showBadge,
            showInSlider: values.showInSlider,
            sliderHeading: values.sliderHeading,
            showInNewsBoard: values.showInNewsBoard,
            newsBoardHeading: values.newsBoardHeading,
            showPost: values.showPost,
            postHeading: values.postHeading,
            postDescription: values.postDescription,
            vacancy: values.vacancy,
            education: values.education,
            region: values.region,
            category: values.category,
            tags: values.tags,
            postCreatedAt: values.postCreatedAt,
            applicationStartDate: values.applicationStartDate,
            lastDateForApplication: values.lastDateForApplication,
            feePayLastDate: values.feePayLastDate,
            admitCardDate: values.admitCardDate,
            examDate: values.examDate,
            resultDate: values.resultDate,
            attachmentData: values.attachmentData,
            body: values.body,
        },
        update(proxy, result) {
            resetForm(initialState)
            const data = proxy.readQuery({
                query: GET_ALL_POSTS_ALL_FIELD,
            })
            data.getPosts = [result.data.createPost, ...data.getPosts]
            proxy.writeQuery({ query: GET_ALL_POSTS_ALL_FIELD, data })

            addToast('Post added successfully.', {
                appearance: 'success',
                autoDismiss: true,
            })
        },
        onError(err) {
            addToast(`${err.graphQLErrors[0].message}`, {
                appearance: 'error',
                autoDismiss: true,
            })
            if (
                err.graphQLErrors[0] &&
                err.graphQLErrors[0].extensions &&
                err.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
            ) {
                context.logout()
            }
        },
    })
    const handleBodyChange = value => {
        onChange('body', value)
    }
    const handleDescriptionChange = value => {
        onChange('postDescription', value)
    }

    const onDropdownSelect = (value, option, field) => {
        onSelect(field, value)
    }

    const handleDateChange = (field, value) => {
        onChange(field, moment(value).format('DD/MM/YYYY'))
    }

    const handleSelectChange = (value, name, optionValue, isMulti) => {
        onSelect(name, value)
    }

    function createNewPost() {
        createPost().then(r => console.log("post created", r))
    }

    const handleDone = props => {
        onChange('attachmentData', props)
        setDone(true)
    }

    const handleRemove = props => {
        onChange('attachmentData', props)
        setDone(true)
    }

    return (
        <div className={'add-post-penal'}>
            {createPostLoading && <Loader />}
            <GridContainer>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={'row padding'}
                >
                    <h4> Show this post in : </h4>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={values.showInSlider}
                                onChange={event =>
                                    onChange(
                                        'showInSlider',
                                        event.target.checked
                                    )
                                }
                                value="showInSlider"
                                name={'showInNewsBoard'}
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                        }}
                        label="Slider"
                        className={'switch1'}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={values.showInNewsBoard}
                                onChange={event =>
                                    onChange(
                                        'showInNewsBoard',
                                        event.target.checked
                                    )
                                }
                                value="showInNewsBoard"
                                name={'showInNewsBoard'}
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                        }}
                        label="News board"
                        className={'switch2'}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={values.showBadge}
                                onChange={event =>
                                    onChange('showBadge', event.target.checked)
                                }
                                value="showBadge"
                                name={'showBadge'}
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                        }}
                        label="Show Badge"
                        className={'switch4'}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={values.showPost}
                                onChange={event =>
                                    onChange('showPost', event.target.checked)
                                }
                                value="showPost"
                                name={'showPost'}
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                        }}
                        label="Show Post"
                        className={'switch3'}
                    />
                    <Divider />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} className={'row'}>
                    <CustomInput
                        labelText="Slider Heading"
                        id="sliderHeading"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'text',
                            autoComplete: 'off',
                            value: values.sliderHeading,
                            // disabled: !values.showInSlider,
                            // error: errors && !!errors.Heading,
                            onChange: e =>
                                onChange('sliderHeading', e.target.value),
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} className={'row'}>
                    <CustomInput
                        labelText="News board Heading"
                        id="newsBoardHeading"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'text',
                            autoComplete: 'off',
                            value: values.newsBoardHeading,
                            // disabled: !values.showInNewsBoard,
                            // error: errors && !!errors.Description,
                            onChange: e =>
                                onChange('newsBoardHeading', e.target.value),
                        }}
                    />
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={12} className={'row'}>
                    <CustomInput
                        labelText="Post Heading"
                        id="postHeading"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'text',
                            autoComplete: 'off',
                            value: values.postHeading,
                            // error: errors && !!errors.Heading,
                            onChange: e =>
                                onChange('postHeading', e.target.value),
                        }}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={'row postDescription'}
                >
                    <h4>Post Description</h4>
                    <Editor
                        apiKey="wrd7uasp7bs8fss7mvs3dl4sikju6uupbuoemx3ex8h4mewp"
                        init={{
                            height: 200,
                            lineHeight: 1,
                            menubar: false,
                            toolbar: false,
                            inline: false,
                            branding: false,
                            contextmenu:
                                'link image imagetools table spellchecker p',
                            quickbars_insert_toolbar: false,
                            quickbars_selection_toolbar:
                                'bold italic removeformat | fontsizeselect quicklink h2 h3 h4 blockquote link emoticons template',
                            paste_data_images: false,
                            paste_html_import: false,
                            paste_word_valid_elements:
                                'b,strong,i,em,h1,h2,table,td,tr,tbody,',
                            paste_webkit_styles: 'font-size',
                            plugins: [
                                'template',
                                'emoticons',
                                'link',
                                'quickbars',
                            ],
                            templates: [
                                {
                                    title: 'New Table',
                                    description: 'creates a new table',
                                    content:
                                        '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                                },
                                {
                                    title: 'Starting my story',
                                    description: 'A cure for writers block',
                                    content: 'Once upon a time...',
                                },
                                {
                                    title: 'New list with dates',
                                    description: 'New List with dates',
                                    content:
                                        '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                                },
                            ],
                        }}
                        value={values.postDescription}
                        onEditorChange={handleDescriptionChange}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <CustomInput
                        labelText="Total vacancy"
                        id="vacancy"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: 'text',
                            autoComplete: 'off',
                            value: values.vacancy,
                            // error: errors && !!errors.vacancyx,
                            onChange: e => onChange('vacancy', e.target.value),
                        }}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <ModifiedSelect
                        isMulti={true}
                        options={optionEducation}
                        label="Select Education"
                        inputId="education"
                        placeholder="Select Education"
                        optionValue="key"
                        optionLabel="name"
                        name="education"
                        selectevalue={values.education || ''}
                        handleChangefn={handleSelectChange}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <ModifiedSelect
                        isMulti={true}
                        options={optionRegion}
                        label="Select job location"
                        inputId="region"
                        placeholder="Select Region"
                        optionValue="key"
                        optionLabel="name"
                        name="region"
                        selectevalue={values.region || ''}
                        handleChangefn={handleSelectChange}
                    />
                </GridItem>

                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <ModifiedSelect
                        isMulti={true}
                        options={optionCategory}
                        label="Select Category"
                        inputId="category"
                        placeholder="Select category"
                        optionValue="key"
                        optionLabel="name"
                        name="category"
                        selectevalue={values.category || ''}
                        handleChangefn={handleSelectChange}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <ModifiedSelect
                        isMulti={true}
                        options={optionTag && optionTag}
                        label="Select Tag"
                        inputId="tag"
                        placeholder="Select tag"
                        optionValue="key"
                        optionLabel="name"
                        name="tags"
                        selectevalue={values.tags || ''}
                        handleChangefn={handleSelectChange}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4} className={'row'}>
                    <FormControl fullWidth>
                        <span>Job Posted at: </span>
                        <Datetime
                            inputProps={{ placeholder: 'Created At' }}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            className={'createdAt'}
                            value={values.postCreatedAt}
                            onChange={value =>
                                handleDateChange('postCreatedAt', value)
                            }
                        />
                    </FormControl>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <span>Application Start Date</span>
                    <Datetime
                        inputProps={{ placeholder: 'Application Start Date' }}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        className={'examLastDate'}
                        value={values.applicationStartDate}
                        onChange={value =>
                            handleDateChange('applicationStartDate', value)
                        }
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <span>Last Date for Apply Online</span>
                    <Datetime
                        inputProps={{
                            placeholder: 'Last Date for Apply Online ',
                        }}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        className={'lastDateApply'}
                        value={values.lastDateForApplication}
                        onChange={value =>
                            handleDateChange('lastDateForApplication', value)
                        }
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <span>Last Date Pay Exam Fee</span>
                    <Datetime
                        inputProps={{ placeholder: 'Last Date Pay Exam Fee' }}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        className={'examLastDate'}
                        value={values.feePayLastDate}
                        onChange={value =>
                            handleDateChange('feePayLastDate', value)
                        }
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <span>Admit Card Available</span>
                    <Datetime
                        inputProps={{ placeholder: 'Admit Card Available' }}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        className={'admitCardAvailable'}
                        value={values.admitCardDate}
                        onChange={value =>
                            handleDateChange('admitCardDate', value)
                        }
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <span>Exam date</span>
                    <Datetime
                        inputProps={{ placeholder: 'Exam date' }}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        className={'examDate'}
                        value={values.examDate}
                        onChange={value => handleDateChange('examDate', value)}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    className={'row padding'}
                >
                    <span>Result Date</span>
                    <Datetime
                        inputProps={{ placeholder: 'Result Date' }}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        className={'resultDay'}
                        value={values.resultDate}
                        onChange={value =>
                            handleDateChange('resultDate', value)
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12} className={'row'}>
                    {/*<Editor />*/}
                    <Editor
                        apiKey="wrd7uasp7bs8fss7mvs3dl4sikju6uupbuoemx3ex8h4mewp"
                        init={{
                            height: 500,
                            menubar: true,
                            value: { content },
                            paste_data_images: true,
                            paste_html_import: false,
                            paste_word_valid_elements:
                                'b,strong,i,em,h1,h2,table,td,tr,tbody,',
                            paste_webkit_styles: 'font-size',
                            plugins: [
                                'template',
                                'emoticons',
                                'searchreplace',
                                'visualblocks',
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks',
                                'insertdatetime',
                                'media',
                                'table',
                                'paste',
                                'code help wordcount',
                            ],
                            toolbar:
                                'undo redo | bold italic underline | fontsizeselect fontselect formatselect | alignleft aligncenter alignright alignjustify table|  numlist bullist | forecolor backcolor removeformat code| pagebreak | charmap emoticons | fullscreen  preview save | insertfile image media template link anchor codesample | ltr rtl',
                            fontsize_formats:
                                '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
                            templates: [
                                // {
                                //     title: 'Fill Form Instruction',
                                //     description: 'Insert Instruction',
                                //     content: `
                                //         <div class="fill-instruction">
                                //         <Icon type="form" />
                                //         <h2 style={{color: "#d81b60 !important;"}}>How to Fill Form</h2>
                                //             <p>Candidate Read the Notification Before Apply the Recruitment Application Form.</p>
                                //             <p>Kindly Check and College the All Document – Eligibility, ID Proof, Address Details, Basic Details.</p>
                                //             <p>Kindly Ready Scan Document Related to Recruitment Form – Photo, Sign, ID Proof, Etc.</p>
                                //             <p>Before Submit the Application Form Must Check the Preview and All Column Carefully.</p>
                                //             <p>If Candidate Required to Paying the Application Fee Must Submit. If You have Not the Required Application Fees Your Form is Not Completed.</p>
                                //             <p>Take A Print Out of Final Submitted Form.</p>
                                //         </div>
                                //         `,
                                // },
                                {
                                    title: 'New Post',
                                    description: 'create new post',
                                    content: `
                                           <div>
                                                <div>
                                                    <h2> [ POST NAME ] </h2>
                                                </div>
                                                <div>
                                                    <h3> Important Dates </h3>
                                                    <ul>
                                                        <li>
                                                            <span>Application Begin : </span>
                                                            <span>01/01/2020</span>
                                                        </li>
                                                        <li>
                                                            <span>Last Date for Apply Online :  </span>
                                                            <span>[ Place holder ] / Notified Soon</span>
                                                        </li>
                                                        <li>
                                                            <span>Pay Examination Fee Last Date :  </span>
                                                            <span>[ Place holder ] / Notified Soon</span>
                                                        </li>
                                                        <li>
                                                            <span>Admit Card Available : </span>
                                                            <span>[ Place holder ] / Notified Soon</span>
                                                        </li>
                                                        <li>
                                                            <span>Exam Date : </span>
                                                            <span>[ Place holder ] / Notified Soon</span>
                                                        </li>
                                                        <li>
                                                            <span>Result Date : </span>
                                                            <span>[ Place holder ] / Notified Soon</span>
                                                        </li>
                                                    </ul>
                                                    <div>
                                                        <h3>Application Fee</h3>
                                                        <ul>
                                                            <li><span>[ Place holder ]</span></li>
                                                            <li><span>[ Place holder ]</span></li>
                                                            <li><span>[ Place holder ]</span></li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h3>Age Limit: </h3>
                                                        <ul>
                                                            <li><span>[ Place holder ]</span></li>
                                                            <li><span>[ Place holder ]</span></li>
                                                            <li><span>[ Place holder ]</span></li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h3>Vacancy Details </h3>
                                                    </div>
                                                    
                                                    
                                                    <div class="fill-instruction">
                                                    <Icon type="form" />
                                                    <h2 style={{color: "#d81b60 !important;"}}>How to Fill Form</h2>
                                                        <p>Candidate Read the Notification Before Apply the Recruitment Application Form.</p>
                                                        <p>Kindly Check and College the All Document – Eligibility, ID Proof, Address Details, Basic Details.</p>
                                                        <p>Kindly Ready Scan Document Related to Recruitment Form – Photo, Sign, ID Proof, Etc.</p>
                                                        <p>Before Submit the Application Form Must Check the Preview and All Column Carefully.</p>
                                                        <p>If Candidate Required to Paying the Application Fee Must Submit. If You have Not the Required Application Fees Your Form is Not Completed.</p>
                                                        <p>Take A Print Out of Final Submitted Form.</p>
                                                    </div>
                                                </div>
                                           </div>
                                        `,
                                },
                            ],
                        }}
                        value={values.body}
                        onEditorChange={handleBodyChange}
                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={'row padding attachment-form'}
                >
                    <h4>File Upload and URL attachment</h4>
                    <Attachment onDone={handleDone} isDone={isDone} />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={'button-group'}
                >
                    <CustomButton
                        className={'button-submit'}
                        color={'rose'}
                        children={'Submit'}
                        onClick={onSubmit}
                    />
                    <CustomButton
                        className={'button-cancel'}
                        color={'default'}
                        children={'Cancel'}
                        onClick={() => resetForm(initialState)}
                    />
                </GridItem>
            </GridContainer>
        </div>
    )
}
