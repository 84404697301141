import { ApolloClient, Apollo } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import fetch from 'isomorphic-unfetch';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';

const urlType = 1; // 1: Server ||| 2: local
const local_url = 'http://localhost:5000/graphql';
// const server_url = 'https://backend.rvndnishadwork.now.sh/graphql';
const server_url = 'https://backend-rvndnishadwork.vercel.app/';
const final_url = urlType === 1 ? server_url : local_url;

const authLinkContext = setContext((_, { headers }) => {
  const token = typeof window !== 'undefined' && localStorage.getItem('jwtToken');
  return {
    fetch: !process.browser && fetch,
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const uploadLink = createUploadLink({
  uri: final_url,
});
const AuthLink = authLinkContext.concat(uploadLink);

export function createClient() {
  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser,
    ssrForceFetchDelay: 100,
    link: ApolloLink.from([AuthLink]),
    cache: process.browser
      ? new InMemoryCache().restore(window.__APOLLO_STATE__)
      : new InMemoryCache(),
  });
}
