import React from "react"
import {Link} from 'react-router-dom'
// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import {makeStyles, AppBar, Toolbar, IconButton, Button, Hidden, Drawer} from "@material-ui/core"

// @material-ui/icons
import Menu from "@material-ui/icons/Menu"
// core components
import styles from "../../assets/jss/material-kit-react/components/headerStyle.js"

const useStyles = makeStyles(styles)

export default function Header(props) {
    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    React.useEffect(() => {
        if (props.changeColorOnScroll) {
            window.addEventListener("scroll", headerColorChange)
        }
        return function cleanup() {
            if (props.changeColorOnScroll) {
                window.removeEventListener("scroll", headerColorChange)
            }
        }
    })
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }
    const headerColorChange = () => {
        const {color, changeColorOnScroll} = props
        const windowsScrollTop = window.pageYOffset
        if (windowsScrollTop > changeColorOnScroll.height) {
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[color])
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[changeColorOnScroll.color])
        } else {
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[color])
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[changeColorOnScroll.color])
        }
    }
    const {color, rightLinks, leftLinks, brand, fixed, absolute, Logo} = props
    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes[color]]: color,
        [classes.absolute]: absolute,
        [classes.fixed]: fixed
    })
    const brandComponent = <Button className={classes.title}>{brand}</Button>
    return (
        <AppBar className={appBarClasses}>
            <Toolbar className={classes.container}>
                {leftLinks !== undefined ? brandComponent : null}
                <div className={classes.flex}>
                    {leftLinks !== undefined ? (
                        <Hidden smDown implementation="css">
                            {leftLinks}
                        </Hidden>
                    ) : (
                        <Link className={classes.logo} to={'/'}><Logo/> {brandComponent} </Link>
                    )}
                </div>
                <Hidden smDown implementation="css">
                    {rightLinks}
                </Hidden>
                <Hidden mdUp>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                    >
                        <Menu/>
                    </IconButton>
                </Hidden>
            </Toolbar>
            <Hidden mdUp implementation="js">
                <Drawer
                    variant="temporary"
                    anchor={"right"}
                    open={mobileOpen}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    onClose={handleDrawerToggle}
                >
                    <div className={classes.appResponsive}>
                        {leftLinks}
                        {rightLinks}
                    </div>
                </Drawer>
            </Hidden>
        </AppBar>
    )
}

Header.defaultProp = {
    color: "white"
}

Header.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark"
        ]).isRequired
    })
}
