import React, {Component, Fragment} from 'react'
import { useQuery } from "react-apollo"
import {GET_ALL_POSTS_NEWS_BOARD} from "../../../../apis/posts"
import {Loader} from '../../../../components'
import {Result} from 'antd'
import {SingleNewsBoard} from './SingleNewsBoard'
import "./index.scss"
export const NewsBoardList = () =>  {
    const {loading, error, data, refetch } = useQuery(GET_ALL_POSTS_NEWS_BOARD, {
        variables: { showInNewsBoard: true },
        suspend: false,
        fetchPolicy: 'cache-and-network'
    })
    return (
        <div className={'newsBoard-list'}>
            {loading ? <Loader /> : (
                data && data.getPosts.length ? (
                    <Fragment>
                        <div className={'header'}>
                            <div>Sr. No</div>
                            <div>Category</div>
                            <div>News Board title</div>
                            <div>Date</div>
                            <div>Action</div>
                        </div>
                        {
                            data.getPosts.map((post, index) => {
                                return <SingleNewsBoard post={post} index={index+1} key={post.id} refetch={refetch}/>
                            })
                        }
                    </Fragment>
                ) : (
                    <Result
                        subTitle="No Post available/ Please refresh to load new posts."
                    />

                )
            )}

        </div>
    )

}

