import React, { useCallback, useEffect, useState } from 'react'
import { FormControlLabel, makeStyles, Switch } from '@material-ui/core'
import styles from '../../../../material-ui/assets/jss/material-kit-react/views/componentsSections/basicsStyle.js'
import { useMutation } from 'react-apollo'
import { EDIT_POST } from '../../../../apis/posts'
const useStyle = makeStyles(styles)

export const SingleSliderPost = props => {
    const [sliderSwitch, setSliderSwitch] = useState(props.post.showInSlider)
    const classes = useStyle()
    const {
        index,
        post: {
            id,
            showInSlider,
            sliderHeading,
            category,
            date: { postCreatedAt },
        },
    } = props

    const [
        editSlider,
        { error: editPostError, loading: editLoading },
    ] = useMutation(EDIT_POST, {
        variables: {
            id: props.post.id,
            showInSlider: sliderSwitch,
        },
        update(proxy, result) {
            // console.log('result', result)
        },
        onError(err) {
            if (err.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
                context.logout()
            }
        },
    })

    const onChange = (field, value) => {
        setSliderSwitch(value)
    }

    // useEffect(()=> {
    //      editAction()
    //  }, [sliderSwitch])

    // function editAction() {
    //     editSlider()
    // }

    return (
        <div className={'row row-body'}>
            <div>{index}</div>
            <div>
                {category.length > 0 &&
                    category.map((tag, index) => (
                        <span key={index}>{tag.name}</span>
                    ))}
            </div>
            <div className={'post-heading'}>{sliderHeading}</div>
            <div className={'post-created'}>{postCreatedAt}</div>
            <div>
                <FormControlLabel
                    control={
                        <Switch
                            size={'small'}
                            checked={sliderSwitch}
                            onChange={event => {
                                onChange('sliderSwitch', event.target.checked)
                            }}
                            name={'sliderSwitch'}
                            value="sliderSwitch"
                            classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar,
                            }}
                        />
                    }
                    classes={{
                        label: classes.label,
                    }}
                    label={''}
                    className={'switch'}
                />
            </div>
        </div>
    )
}
