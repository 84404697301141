import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import {GET_ALL_POSTS_ALL_FIELD, GET_ALL_POSTS_SLIDER} from '../../../apis'
import { Loader } from '../../../components'

const PopularPosts = () => {
    const { loading, error, data } = useQuery(GET_ALL_POSTS_SLIDER, {})
    console.log('data', data)
    return (
        <div className="popular-post">
            {loading ? (
                <Loader />
            ) : (
                data &&
                data.getPosts &&
                data.getPosts.map((item, index) => (
                    <div key={item.id}>
                        <span className="index">{index + 1}. </span>
                        <span className="link">
                            <Link
                                to={
                                    '/posts/' +
                                    item.postHeading.split(' ').join('-') +
                                    '_' +
                                    item.id
                                }
                            >
                                {item.postHeading}
                            </Link>
                        </span>
                    </div>
                ))
            )}
        </div>
    )
}

export default PopularPosts
