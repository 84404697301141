import React from 'react'
import { format } from 'date-fns';

import "./dates.scss"
export const Dates = (props) =>  {
    const date = props.date;
    const returnDate = string => {
        const day = string.split("/")[0]
        const month = parseInt(string.split("/")[1]) - 1
        const year = string.split("/")[2]
        return new Date (year, month, day)
    }
        return (
            <div className="dates">
                <div className="container">
                    <div className="wrapper">
                        <div className="arrow-steps clearfix">
                            {/*<div className="step current"><span> Application Start Date</span><br/><br/><span className="date"> { date.applicationStartDate ? format(returnDate(date.applicationStartDate), "dd MMM, yyyy") : "Notified Soon"  } </span></div>*/}
                            <div className="step"><span> Application Start Date</span><br/><br/><span className="date"> { date.applicationStartDate ? format(returnDate(date.applicationStartDate), "dd MMM, yyyy") : "Notified Soon"  } </span></div>
                            <div className="step"><span>Application End Date</span><br/><br/><span className="date"> { date.lastDateForApplication ? format(returnDate(date.lastDateForApplication), "dd MMM, yyyy") :  "Notified Soon" } </span></div>
                            <div className="step"><span>Last Date to pay Fee</span><br/><br/><span className="date"> { date.feePayLastDate ? format(returnDate(date.feePayLastDate), "dd MMM, yyyy") : "Notified Soon" } </span></div>
                            <div className="step"><span>Admit Card available</span><br/><br/><span className="date"> { date.admitCardDate ? format(returnDate(date.admitCardDate), "dd MMM, yyyy") : "Notified Soon" } </span></div>
                            <div className="step"><span>Exam <br/>date</span><br/><br/><span className="date"> { date.examDate ? format(returnDate(date.examDate), "dd MMM, yyyy") : "Notified Soon" } </span></div>
                            <div className="step"><span>Result <br/>Date</span><br/><br/><span className="date"> { date.resultDate ? format(returnDate(date.resultDate), "dd MMM, yyyy") : "Notified Soon"} </span></div>
                            {/*<div className="step"><span>Step 7</span></div>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
}

