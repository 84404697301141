import gql from 'graphql-tag'

export const GET_CATEGORY = gql`
    query getCategory($visible: Boolean)
    {
        getCategories(categorySearchInput : {visible: $visible}){
            id
            categoryTitle
            categoryType{
                typeCode
                typeLabel
            }
            visible
            order
        }
    }
`;

export const CREATE_CATEGORY = gql`
    mutation createCategory($categoryTitle: String!, $categoryType: CategoryTypeInput, $visible: Boolean!, $order: Int ){
        createCategory(
            categoryInput: {
                categoryTitle: $categoryTitle
                visible: $visible
                categoryType: $categoryType
                order: $order
            }) {
            id
            categoryTitle
            categoryType{
                typeCode
                typeLabel
            }
            visible
            order
        }
    }
`;

export const EDIT_CATEGORY = gql`
    mutation createCategory($categoryId: String!, $categoryTitle: String!, $categoryType: CategoryTypeInput, $visible: Boolean!, $order: Int ){

        editCategory(
            categoryInput: {
                categoryId: $categoryId
                categoryTitle: $categoryTitle
                visible: $visible
                categoryType: $categoryType
                order: $order
            }) {
            id
            categoryTitle
            categoryType{
                typeCode
                typeLabel
            }
            visible
            order
        }
    }
`;

export const DELETE_CATEGORY = gql`
    mutation deleteCategory($categoryId: ID!)
    {
        deleteCategory(categoryIds: [$categoryId])
    }
`;
